<template>
  <div class="base-style">
    <div class="plugins">
      <div class="left">
        <slot name="left" />
      </div>
      <div class="right">
        <slot name="right" />
      </div>
    </div>
    <slot name="before" />
    <!-- 中文字体 -->
    <div class="base-item">
      <span class="base-label" :style="{ width: labelWidth }">中文：</span>
      <el-select v-model="value.font_family_cn" :placeholder="findKey(placeholder.font_family_cn, fontFamilyCn) ||  '宋体（默认）'"
        class="style-tool-select" filterable :popper-append-to-body="false" :clearable="clearable">
        <el-option
          v-for="(value, key) in fontFamilyCn"
          :key="value"
          :label="key"
          :value="value">
        </el-option>
      </el-select>
    </div>
    <!-- 英文字体 -->
    <div class="base-item">
      <span class="base-label" :style="{ width: labelWidth }">英文：</span>
      <el-select v-model="value.font_family_en" :placeholder="findKey(placeholder.font_family_en, fontFamilyEn) ||  '白正（默认）'"
        class="style-tool-select" filterable :popper-append-to-body="false" :clearable="clearable">
        <el-option
          v-for="(value, key) in fontFamilyEn"
          :key="value"
          :label="key"
          :value="value">
        </el-option>
      </el-select>
    </div>
    <!-- 数字字体 -->
    <div class="base-item">
      <span class="base-label" :style="{ width: labelWidth }">数字：</span>
      <el-select v-model="value.font_family_num" :placeholder="findKey(placeholder.font_family_num, fontFamilyEn) || '白正（默认）'"
        filterable class="style-tool-select" :popper-append-to-body="false" :clearable="clearable">
        <el-option
          v-for="(value, key) in fontFamilyEn"
          :key="value"
          :label="key"
          :value="value">
        </el-option>
      </el-select>
    </div>
    <!-- 字号 -->
    <div class="base-item">
      <span class="base-label" :style="{ width: labelWidth }">字号：</span>
      <el-select v-model="value.font_size" :placeholder="placeholderFontSize" :popper-append-to-body="false" :clearable="clearable" class="style-tool-select">
        <el-option v-for="item in fontSize" :key="item.key" :label="item.key" :value="item.value" />
      </el-select>
    </div>
    <div class="base-item" v-if="value.line_spacing != null && !exclude.includes('line_spacing')">
      <span class="base-label" :style="{ width: labelWidth }">行间距：</span>
      <format-input type="number" v-model="value.line_spacing" min="0" :placeholder="placeholder.line_spacing || '0.5字'" />
    </div>
    <div class="base-item" v-if="value.line_height != null">
      <span class="base-label" :style="{ width: labelWidth }">行高：</span>
      <UnitInput min="0.0" max="50.0" step="0.1" v-model.number="value.line_height" :placeholder="placeholder.line_height || '1'" unit="字" />
      <span class="unit">字</span>
    </div>
    <div class="base-item" v-if="value.text_align != null && !exclude.includes('text_align')">
      <span class="base-label" :style="{ width: labelWidth }">文字对齐：</span>
      <el-select v-model="value.text_align" :placeholder="findKey(placeholder.text_align, fontMargin) || '居左'" :popper-append-to-body="false" :clearable="clearable" class="style-tool-select">
        <el-option
          v-for="(value, key) in fontMargin"
          :key="value"
          :label="key"
          :value="value">
        </el-option>
      </el-select>
    </div>
    <!-- 字体样式板, 加粗，倾斜，删除线，颜色选择器 -->
    <div class="base-item" v-if="openColor">
      <span class="base-label" :style="{ width: labelWidth }">字体颜色：</span>
      <Color :value="value.font_color" :globalColor="styleData.book_default_style.global_color" />
    </div>
    <div class="base-item" v-if="value.font_background != null && !exclude.includes('font_background')">
      <span class="base-label" :style="{ width: labelWidth }">背景色：</span>
      <Color :value="value.font_background" :globalColor="styleData.book_default_style.global_color" />
    </div>
    <slot />
  </div>
</template>
<script>
import { fontSize, fontFamilyCn, fontFamilyEn, fontMargin } from '@/assets/styleTool/map';
import { findKey } from '@/scripts/helper';
import FormatInput from './FormatInput';
import UnitInput from './UnitInput';
import Color from './Color';
export default {
  components: {
    FormatInput,
    UnitInput,
    Color
  },
  name: 'text-style',
  props: {
    extra_font_list: {
      type: Array,
    },
    styleData: {
      type: Object,
      default () {
        return {
          book_default_style: {
            global_color: []
          }
        };
      }
    },
    clearable: {
      type: Boolean,
      default: true
    },
    labelWidth: {
      type: String,
      default: '70px'
    },
    exclude: {
      type: Array,
      default () {
        return [];
      }
    },
    value: {
      // required: true,
      type: Object,
      default: function () {
        return {
          font_color: {
            rgb: [0, 0, 0],
            cmyk: []
          },
          font_family_cn: '',
          font_family_en: '',
          font_family_num: '请选择',
          font_size: '请选择'
        };
      }
    },
    placeholder: {
      type: Object,
      default: function () {
        return {
          font_color: {
            rgb: [0, 0, 0],
            cmyk: []
          },
          font_family_cn: '宋体',
          font_family_en: 'Times New Roman',
          font_family_num: '请选择',
          font_size: '请选择'
        };
      }
    },
    openColor: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    fontFamilyCn () {
      const { extra_font_list } = this;
      if (!extra_font_list) return fontFamilyCn;
      const temp = {};
      extra_font_list.filter(item => item.type === 'cn').forEach(item => temp[item.font_name] = item.font_name);
      return { ...fontFamilyCn, ...temp };
    },
    fontFamilyEn () {
      const { extra_font_list } = this;
      if (!extra_font_list) return fontFamilyEn;
      const temp = {};
      extra_font_list.filter(item => item.type === 'en').forEach(item => temp[item.font_name] = item.font_name);
      return { ...fontFamilyEn, ...temp };
    },
    placeholderFontSize () {
      return this.fontSize.find(item => item.value === this.placeholder.font_size)?.key || '10.5';
    },
  },
  data () {
    return {
      fontSize,
      fontMargin
    };
  },
  methods: {
    findKey,
    transformColor (val) {
      let rgb = val && val.match(/(?<=\()(\d+), *(\d+), *(\d+)(?=\))/)[0] || '';
      if (rgb) {
        rgb = rgb.split(',').map(item => +item);
      } else {
        rgb = this.clearable ? [] : [0, 0, 0];
      }
      this.value.font_color.rgb = rgb;
      if (rgb.length) {
        this.value.font_color.cmyk = this.rgb2cmyk(rgb);
      } else {
        this.value.font_color.cmyk = [];
      }
    },
    inputCmyk (val, position) {
      val = +val.replace(/[^0-9]/g, '');
      val = val < 0 ? 0
        : val > 100 ? 100 : val;
      const { font_color, font_color: { cmyk } } = this.value;
      cmyk.splice(position, 1, val);
      if (!cmyk || cmyk.length !== 4) return;
      font_color.rgb = this.cmykToRGB(cmyk);
    },
    rgb2cmyk ([r, g, b]) {
      let c = 1 - (r / 255);
      let m = 1 - (g / 255);
      let y = 1 - (b / 255);
      let k = Math.min(c, Math.min(m, y));
      c = Math.round((c - k) / (1 - k) * 100);
      m = Math.round((m - k) / (1 - k) * 100);
      y = Math.round((y - k) / (1 - k) * 100);
      k = Math.round(k * 100);
      c = isNaN(c) ? 0 : c;
      m = isNaN(m) ? 0 : m;
      y = isNaN(y) ? 0 : y;
      k = isNaN(k) ? 0 : k;
      return [c, m, y, k];
    },
    cmykToRGB ([c, m, y, k]) {
      const r = Math.round(255 * (1 - c / 100) * (1 - k / 100));
      const g = Math.round(255 * (1 - m / 100) * (1 - k / 100));
      const b = Math.round(255 * (1 - y / 100) * (1 - k / 100));
      return [r, g, b];
    },
    getColor (rgb) {
      if (!rgb || !rgb.length) {
        return 'rgb(0, 0, 0)';
      }
      return `rgb(${rgb.join()})`;
    },
  }
};
</script>
<style scoped lang="scss">
  .base-style {
    display: flex;
    flex-wrap: wrap;
    .plugins {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    .base-item {
      flex: none;
      display: flex;
      align-items: center;
      padding: 10px;
    }
    .base-label {
      height: 17px;
      font-size: 14px;
      line-height: 17px;
      color: #000;
      text-align: right;
    }
    .unit {
      font-size: 12px;
      color: #ccc;
      margin-left: 4px;
    }
  }
</style>
