<template>
  <div class="style-tool-color">
    <el-popover
      placement="bottom"
      width="320"
      popper-class="style-tool-color-popover"
      trigger="click">
      <div>
        <div class="style-tool-cmyk-box">
          <el-color-picker
            color-format="rgb"
            v-model="color"
            :predefine="predefineColors"
            @active-change="transformColor">
          </el-color-picker>
          <span class="base-label p-r-4 m-l-10" style="min-width: 45px">CMYK:</span>
          <span v-if="value.cmyk"><el-input :value="value.cmyk[0]" @input="inputCmyk($event, 0)" placeholder="C"></el-input></span>
          <span v-if="value.cmyk"><el-input :value="value.cmyk[1]" @input="inputCmyk($event, 1)" placeholder="M"></el-input></span>
          <span v-if="value.cmyk"><el-input :value="value.cmyk[2]" @input="inputCmyk($event, 2)" placeholder="Y"></el-input></span>
          <span v-if="value.cmyk"><el-input :value="value.cmyk[3]" @input="inputCmyk($event, 3)" placeholder="K"></el-input></span>
        </div>
      </div>
      <div class="style-tool-cmyk-default-color m-t-10" v-if="globalColor && globalColor.length">
        <div v-for="(item, index) in globalColor" :key="index" :style="{ backgroundColor: `rgb(${item.rgb.join(',')})` }" class="default-color-item" @click="selectDefaultColor(item)" :title="`cmyk(${item.cmyk.join(', ')})`"></div>
      </div>
      <div class="style-tool-color-toggle" slot="reference" :style="{ 'background-color': color }"></div>
    </el-popover>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  props: {
    defaultColor: {
      type: String
    },
    globalColor: {
      type: Array
    },
    value: {
      type: Object,
      required: true
    },
    placehodler: {
      type: Object,
      default () {
        return {
          rgb: [0, 0, 0]
        };
      }
    },
  },
  data () {
    return {
      predefineColors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585'
      ]
    };
  },
  computed: {
    color () {
      const { rgb } = this.value;
      if (rgb && rgb.length) return `rgb(${rgb.join()})`;
      const { pRgb } = this.placehodler || {};
      if (pRgb && pRgb.length) return `rgb(${pRgb.join()})`;
      return this.defaultColor || 'rgb(255, 255, 255)';
    }
  },
  methods: {
    selectDefaultColor ({ cmyk, rgb }) {
      this.value.rgb = _.cloneDeep(rgb);
      this.value.cmyk = _.cloneDeep(cmyk);
    },
    inputCmyk (val, position) {
      val = +val.replace(/[^0-9]/g, '');
      val = val < 0 ? 0
        : val > 100 ? 100 : val;
      const { cmyk } = this.value;
      cmyk.splice(position, 1, val);
      if (!cmyk || cmyk.length !== 4) return;
      this.value.rgb = this.cmykToRGB(cmyk);
    },
    rgb2cmyk ([r, g, b]) {
      let c = 1 - (r / 255);
      let m = 1 - (g / 255);
      let y = 1 - (b / 255);
      let k = Math.min(c, Math.min(m, y));
      c = Math.round((c - k) / (1 - k) * 100);
      m = Math.round((m - k) / (1 - k) * 100);
      y = Math.round((y - k) / (1 - k) * 100);
      k = Math.round(k * 100);
      c = isNaN(c) ? 0 : c;
      m = isNaN(m) ? 0 : m;
      y = isNaN(y) ? 0 : y;
      k = isNaN(k) ? 0 : k;
      return [c, m, y, k];
    },
    cmykToRGB ([c, m, y, k]) {
      const r = Math.round(255 * (1 - c / 100) * (1 - k / 100));
      const g = Math.round(255 * (1 - m / 100) * (1 - k / 100));
      const b = Math.round(255 * (1 - y / 100) * (1 - k / 100));
      return [r, g, b];
    },
    transformColor (val) {
      console.log(val);
      let rgb = val && val.match(/(?<=\()(\d+), *(\d+), *(\d+)(?=\))/)[0] || '';
      if (rgb && rgb.length) rgb = rgb.split(',').map(item => +item);
      this.value.rgb = rgb;
      if (rgb.length) {
        this.value.cmyk = this.rgb2cmyk(rgb);
      } else {
        this.value.cmyk = [];
      }
    },
  },
};
</script>
<style lang="scss">
.style-tool-cmyk-default-color {
  display: flex;
  flex-wrap: wrap;
  .default-color-item {
    flex: none;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 4px;
    margin-bottom: 4px;
  }
}
</style>

<style lang="scss" scoped>
.style-tool-color {
  display: inline-block;
  margin-left: 4px;
  padding: 2px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  border-radius: 4px;
  width: 80px;
  height: 20px;
    &-toggle {
      position: relative;
      border-radius: 2px;
      width: 100%;
      height: 100%;
      transition: all .3s;
      text-align: center;
      border: 1px solid #999;
      cursor: pointer;
      outline: none;
    }
    /deep/ .el-color-picker {
      opacity: unset!important;
      .el-color-picker__trigger {
        width: 80px;
        height: 17px;
        padding: 2px;
        background-color: #fff;
      }
    }
  }
</style>
