<template>
  <div class="style-demo">
    <header-bar>
      <template #left>
        <div
          class="title style-demo-title"
          style="font-weight: 700; cursor: pointer"
        >
          <div>样式统配(样式demo)</div>
        </div>
      </template>
      <template #right>
        <!-- 按钮组 -->
        <div class="btn-box">
          <el-button size="mini" type="primary" @click="onComplete"
            >{{$route.query.style_id || mode === 'edit' ? '修改' : '创建'}}</el-button
          >
          <el-button size="mini" @click="goBack()">返回</el-button>
        </div>
      </template>
    </header-bar>
    <div class="body-container" v-if="styleData">
      <div v-if="mode !== 'edit'" class="body-header">
        <el-form class="body-header-item" size="mini">
          <el-form-item label="样式名称">
            <el-input v-model="styleConfig.name" placeholder="请输入样式名称"></el-input>
          </el-form-item>
        </el-form>
        <el-form class="body-header-item" size="mini">
          <el-form-item :rules="[{ required: true }]" label="样式类型">
            <el-dropdown class="c-p" @command="handleType" placement="bottom-start" :popper-append-to-body="false">
              <span>
                {{selectedType}}<i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(value, key) in types" :key="value" :command="value">{{key}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>
        </el-form>
        <el-form class="body-header-item" size="mini">
          <el-form-item label="样式大小">
            <el-dropdown class="c-p" @command="handleSize" placement="bottom-start" :popper-append-to-body="false">
              <span>
                {{selectedSize}}<i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(value, key) in size" :key="value" :command="value">{{key}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>
        </el-form>
      </div>
      <div class="flex">
        <el-form size="mini" label-width="100px" class="fbd-form m-r-40" label-position="left">
          <el-form-item label="1. 导出类型">
            <el-radio v-model="output_cfg.type" label="founder">方正</el-radio>
          </el-form-item>
        </el-form>
        <el-form size="mini" label-width="100px" class="fbd-form m-r-40" label-position="left">
          <el-form-item label="2. 导出版本">
            <el-radio v-model="output_cfg.version" label="10.0">10.0</el-radio>
            <el-radio v-model="output_cfg.version" label="11.0">11.0</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <div class="flex">
        <el-form size="mini" label-width="100px" class="fbd-form" label-position="left">
          <el-form-item label="3.成品尺寸：">
            <el-row class="m-b-10">
              <el-col :span="8" class="input-box m-r-20">
                <span class="label">高</span>
                <el-input type="number" :value="parseFloat(page_cfg.page_size.height)" class="input-number" @input="inputPageSize($event, 'height')"></el-input>
                <span class="unit">mm</span>
              </el-col>
              <el-col :span="8" class="input-box m-r-20">
                <span class="label">宽</span>
                <el-input type="number" :value="parseFloat(page_cfg.page_size.width)" class="input-number" @input="inputPageSize($event, 'width')"></el-input>
                <span class="unit">mm</span>
              </el-col>
              <el-col :span="4" class="input-box">
                <el-dropdown class="c-p" @command="changePageSize" placement="bottom-start" :popper-append-to-body="false">
                  <span>
                    {{actPageSize}}<i class="el-icon-caret-bottom"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="(item, key) in computedPageSizes" :key="key" :command="item">
                      <span class="type m-r-10">{{ item.name }}</span>
                      <span class="size" v-if="item.width && item.height"
                        >{{ item.height }} x {{ item.width }}</span
                      >
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <el-form size="mini" label-width="100px" class="fbd-form" label-position="left">
          <el-form-item label="4. 版心宽高">
            <el-row class="m-b-10">
              <el-col :span="8" class="input-box m-r-20">
                <span class="label">高</span>
                <el-input
                  type="number"
                  :value="parseFloat(page_cfg.page_core_size.height)"
                  class="input-number"
                  @input="inputPageCoreSize($event, 'height')"
                ></el-input>
                <span class="unit">mm</span>
              </el-col>
              <el-col :span="8" class="input-box m-r-20">
                <span class="label">宽</span>
                <el-input
                  type="number"
                  :value="parseFloat(page_cfg.page_core_size.width)"
                  class="input-number"
                  @input="inputPageCoreSize($event, 'width')"
                ></el-input>
                <span class="unit">mm</span>
              </el-col>
              <el-col :span="4" class="input-box">
                <el-dropdown class="c-p" @command="changePageCoreSize" placement="bottom-start" :popper-append-to-body="false">
                  <span>
                    {{actPageCoreSize}}<i class="el-icon-caret-bottom"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="(item, key) in computedPageCoreSize" :key="key" :command="item">
                      <span class="type m-r-10">{{ item.name }}</span>
                      <span class="size" v-if="item.width && item.height"
                        >{{ item.height }} x {{ item.width }}</span
                      >
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </div>
      <div class="flex">
        <el-form size="mini" label-width="100px" class="fbd-form" style="margin-right: 180px" label-position="left">
          <el-form-item label="5.上空下空：">
            <el-row class="connection-box">
              <div class="connection-style">
                <svg-icon name="chain" class="icon-connection"></svg-icon>
                <span
                  @click="autoSetMargin('v')"
                  class="btn-auto-center c"
                  >
                  自动找齐居中
                </span>
              </div>
              <div class="input-box">
                <span class="label m-b-10">上空</span>
                <el-input type="number" :value="parseFloat(page_cfg.page_margin.top)" class="input-number" @input="inputPageMarginSize($event, 'top')"></el-input>
                <span class="unit">mm</span>
              </div>
              <div class="input-box">
                <span class="label">下空</span>
                <el-input type="number" :value="parseFloat(page_cfg.page_margin.bottom)" class="input-number" @input="inputPageMarginSize($event, 'bottom')"></el-input>
                <span class="unit">mm</span>
              </div>
            </el-row>
          </el-form-item>
        </el-form>
        <el-form size="mini" label-width="100px" class="fbd-form" label-position="left">
          <el-form-item label="6.订口切口：">
            <el-row class="connection-box">
              <div class="connection-style">
                <svg-icon name="chain" class="icon-connection"></svg-icon>
                <span
                  @click="autoSetMargin('h')"
                  class="btn-auto-center c"
                  >
                  自动找齐居中
                </span>
              </div>
              <div class="input-box m-b-10">
                <span class="label">订口</span>
                <el-input type="number" :value="parseFloat(page_cfg.page_margin.left)" class="input-number" @input="inputPageMarginSize($event, 'left')"></el-input>
                <span class="unit">mm</span>
              </div>
              <div class="input-box">
                <span class="label">切口</span>
                <el-input type="number" :value="parseFloat(page_cfg.page_margin.right)" class="input-number" @input="inputPageMarginSize($event, 'right')"></el-input>
                <span class="unit">mm</span>
              </div>
            </el-row>
          </el-form-item>
        </el-form>
      </div>
      <el-form size="mini" label-width="100px" class="fbd-form" label-position="left">
        <el-form-item label="7.文字样式">
          全局文字样式：
          <div class="style-config-container clearfix">
            <BaseStyle :styleData="styleData" :extra_font_list="styleData.extra_font_list" v-model="globalTextValue" :clearable="false" :exclude="['text_align']" />
          </div>
          表格样式：
          <!-- <el-button type="text" @click="addTempTextStyle">+新增</el-button> -->
          <div class="style-config-container clearfix">
            <BaseStyle :styleData="styleData" :extra_font_list="styleData.extra_font_list" v-model="partial_cfg.table_font" :clearable="false" :exclude="['text_align']" :openColor="false"/>
            <div class="base-style">
              <div class="base-item">
                <el-checkbox v-model="partial_cfg.table_font.del_indent" style="padding-left: 30px;padding-bottom: 10px;" class="checkbox-width style-tool-cmyk-box">是否删除缩进</el-checkbox>
              </div>
            </div>
            <!-- <i class="el-icon-delete delete-icon" @click="deleteTempTextList(index)"></i> -->
          </div>
          简单表格样式：
          <div class="style-config-container clearfix">
            <div class="base-style">
              <div class="base-item">
                <span class="base-label" :style="{ width: labelWidth }">上空：</span>
                <format-input
                  type="number"
                  v-model="partial_cfg.table_font.simple_table_cfg.top_space"
                  class="style-tool-input"
                  style="width: 100px"
                />
              </div>
              <div class="base-item">
                <span class="base-label" :style="{ width: '50px' }">下空：</span>
                <format-input
                  type="number"
                  v-model="partial_cfg.table_font.simple_table_cfg.bottom_space"
                  class="style-tool-input"
                  style="width: 100px"
                />
              </div>
            </div>
          </div>
        </el-form-item>
        <h3>页面配置</h3>
        <el-tabs v-model="allNormalConfig" tab-position="left">
          <el-tab-pane label="分栏配置" name="global_column_fbd" lazy>
            <span class="column_fbd_label"><span style="color: red">*</span> 栏宽</span>
            <format-input
              type="number"
              v-model="page_cfg.global_column_fbd.min_column_width"
              class="core-format-input"
            />
            <FbdEditor
              v-if="
                page_cfg &&
                page_cfg.global_column_fbd &&
                page_cfg.global_column_fbd.enable_fbd
              "
              v-model="page_cfg.global_column_fbd.code_template"
              :options="{ height: editorHeight, removeButtons }"
            />
          </el-tab-pane>
          <el-tab-pane label="Pro 配置" name="pro_fbd" lazy>
            <FbdEditor
              v-if="
                page_cfg &&
                page_cfg.pro_fbd
              "
              v-model="page_cfg.pro_fbd.code_template"
              :options="{ height: editorHeight, removeButtons: removeButtonsAll }"
            />
          </el-tab-pane>
          <el-tab-pane label="左右页眉" name="margin_components_header_page" lazy>
            <div style="margin-bottom: 10px;align-items: center;" class="flex">
              是否区分左右页眉：
              <el-radio-group v-model="pageHeaderRadio" size="mini">
                <el-radio :label="false">否</el-radio>
                <el-radio :label="true">是</el-radio>
              </el-radio-group>
            </div>
            <div v-if="!pageHeaderRadio" style="width: 90%;display:flex;">
              <FbdEditor
                style="display: inline-block;width: 400px;margin-right: 15px;flex: 1"
                v-if="
                  margin_components_header_page &&
                  margin_components_header_page.fbd &&
                  margin_components_header_page.fbd.enable_fbd
                "
                v-model="margin_components_header_page.fbd.code_template"
                :options="{ height: editorHeight, removeButtons: removeButtonsPageHeader }"
              />
              <div style="display: flex; flex-direction: column">
                <el-checkbox v-model="margin_components_header_page.fbd.is_follow_chapter">是否跟随标题</el-checkbox>
              </div>
            </div>
            <div v-else>
              左页眉：
              <div style="width: 90%;display:flex;">
                <FbdEditor
                  style="display: inline-block;width: 400px;margin-right: 15px;flex: 1"
                  v-if="
                    margin_components_header_odd &&
                    margin_components_header_odd.fbd &&
                    margin_components_header_odd.fbd.enable_fbd
                  "
                  v-model="margin_components_header_odd.fbd.code_template"
                  :options="{ height: editorHeight, removeButtons: removeButtonsPageHeader }"
                />
                <div style="display: flex; flex-direction: column">
                  <el-checkbox v-model="margin_components_header_odd.fbd.is_follow_chapter">是否跟随标题</el-checkbox>
                </div>
              </div>
              右页眉：
              <div style="width: 90%;display:flex;">
                <FbdEditor
                  style="display: inline-block;width: 400px;margin-right: 15px;flex: 1"
                  v-if="
                    margin_components_header_even &&
                    margin_components_header_even.fbd &&
                    margin_components_header_even.fbd.enable_fbd
                  "
                  v-model="margin_components_header_even.fbd.code_template"
                  :options="{ height: editorHeight, removeButtons: removeButtonsPageHeader }"
                />
                <div style="display: flex; flex-direction: column">
                  <el-checkbox v-model="margin_components_header_even.fbd.is_follow_chapter">是否跟随标题</el-checkbox>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="左右页脚" name="margin_components_footer" lazy>
            <FbdEditor
              v-if="
                margin_components_footer &&
                margin_components_footer.fbd &&
                margin_components_footer.fbd.enable_fbd
              "
              v-model="margin_components_footer.fbd.code_template"
              :options="{ height: editorHeight, removeButtons }"
            />
          </el-tab-pane>
          <el-tab-pane label="左边栏" name="margin_components_left" lazy>
            <FbdEditor
              v-if="
                margin_components_left &&
                margin_components_left.fbd &&
                margin_components_left.fbd.enable_fbd
              "
              v-model="margin_components_left.fbd.code_template"
              :options="{ height: editorHeight, removeButtons }"
            />
          </el-tab-pane>
          <el-tab-pane label="右边栏" name="margin_components_right" lazy>
            <FbdEditor
              v-if="
                margin_components_right &&
                margin_components_right.fbd &&
                margin_components_right.fbd.enable_fbd
              "
              v-model="margin_components_right.fbd.code_template"
              :options="{ height: editorHeight, removeButtons }"
            />
          </el-tab-pane>
          <el-tab-pane label="额外内容配置" name="extra_content" lazy>
            <div style="width: 90%;display:flex;">
              <FbdEditor
                style="display: inline-block;width: 400px;margin-right: 15px;flex: 1"
                v-if="
                  partial_cfg &&
                  partial_cfg.extra_content
                "
                v-model="partial_cfg.extra_content.code_template"
                :options="{ height: editorHeight, removeButtons: removeButtons }"
              />
              <div style="display: flex; flex-direction: column">
                <div>
                  位置：
                  <el-select size="mini" v-model="partial_cfg.extra_content.location">
                    <el-option label="全文开头" value="start" />
                    <el-option label="一级标题前" value="chapter_1" />
                  </el-select>
                  <div style="margin-top: 10px">
                    <el-checkbox v-model="partial_cfg.extra_content.is_in_column">标题是否在栏内</el-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="WB 配置" name="wb_cfg" lazy>
            一行 4/2 个:
            <FbdEditor
              v-if="
                partial_cfg &&
                partial_cfg.wb_cfg &&
                partial_cfg.wb_cfg['4']
              "
              v-model="partial_cfg.wb_cfg['4'].fbd"
              :options="{ height: 50, removeButtons: removeButtonsAll }"
            />
            <br>
            一行 3 个:
            <FbdEditor
              v-if="
                partial_cfg &&
                partial_cfg.wb_cfg &&
                partial_cfg.wb_cfg['3']
              "
              v-model="partial_cfg.wb_cfg['3'].fbd"
              :options="{ height: 50, removeButtons: removeButtonsAll }"
            />
          </el-tab-pane>
        </el-tabs>

        <div v-if="selectedType !== '期刊'">
          <h3>
            标题配置
          </h3>
          <div class="chapter_class">新增section名称：
            <el-input size="mini" v-model="active_section_name_tmp"></el-input>
            <el-button size="mini" style="margin-left: 5px" type="primary" @click="addChapterSection">
              增加section
            </el-button>
          </div>
          <el-tabs v-model="allChapterConfig" tab-position="left" @tab-click="changeChatperSection">
            <el-tab-pane :name="chapters" v-for="(chapters, index) in chapter_section_list" :key="index" >
              <template slot="label">
                <span> {{ chapters }} </span>
              </template>
              <el-button size="mini" style="margin:0 15px 15px 0" type="primary" :disabled="class_cfg_list_chapter_list.length >= 7" @click="addChapter">
                增加标题
              </el-button>
              <div class="rename_chapter_class">
                重命名setction：
                <el-input size="mini" v-model="edit_section_name"></el-input>
                <el-button size="mini" style="margin-bottom: 15px" type="primary" @click="renameChapter">
                  确认
                </el-button>
                <el-button size="mini" type="danger" @click="deleteSection">
                  删除当前section
                </el-button>
              </div>
              <el-tabs class="chapter_tabs" type="border-card" v-model="active_chapter_tap" tab-position="top" v-if="class_cfg_list_chapter_list_all">
                <el-tab-pane v-for="(level, index) in class_cfg_list_chapter_list" :key="index" :label="getLevelCn(index) + '级标题'" :name="'class_cfg_list_chapter_level' + (index + 1)" lazy>
                  <div style="width: 90%;display:flex;">
                    <FbdEditor
                      style="display: inline-block;width: 400px;margin-right: 15px;flex: 1"
                      v-if="
                        level &&
                        level.fbd &&
                        level.fbd.enable_fbd
                      "
                      v-model="level.fbd.code_template"
                      :options="{ height: editorHeight, removeButtons: removeButtonsChapter }"
                    />
                    <div style="display: flex; flex-direction: column" v-if="level">
                      <el-checkbox v-model="level.fbd.is_in_column">标题是否在栏内</el-checkbox>
                      <el-checkbox v-model="level.fbd.is_lm">是否添加另面</el-checkbox>
                      <el-checkbox v-model="level.fbd.need_serial_number">是否需要题号</el-checkbox>
                    </div>
                  </div>
                  <el-checkbox v-model="level.fbd.is_fl">标题是否需要分栏</el-checkbox>
                  <FbdEditor
                    style="display: block;width: 500px;margin-right: 15px;"
                    v-if="
                      level &&
                      level.fbd &&
                      level.fbd.is_fl
                    "
                    v-model="level.fbd.fl_template"
                    :options="{ height: 50, removeButtons: removeButtonsAll }"
                  />
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>

            <el-tab-pane label="关键字" name="xml-keyword" lazy>
              <div v-for="(item, key) in partial_cfg.keyword_cfg" :key="item.id" style="display: flex;margin-bottom: 15px;width: 90%;">
                <FbdEditor
                  v-model="
                    item.fbd_code
                  "
                  style="display: inline-block;width: 400px;margin-right: 15px;flex: 1"
                  :options="{ height: 80, removeButtons: removeXmlKeyWord }"
                />
                <div style="display: flex; flex-direction: column">
                  <el-checkbox v-model="item.inline">关键字是否在行内</el-checkbox>
                  <el-checkbox v-model="item.is_in_column">关键字是否在栏内</el-checkbox>
                  <el-checkbox v-model="item.del_image">是否删除内容中的装饰图</el-checkbox>
                  <el-button @click="deleteXmlKeyWord(key)" type="danger" size="mini" style="margin-top: 10px; width: 100px">删除该关键字</el-button>
                </div>
              </div>
              <el-button size="mini" @click="addQuestionKeyWord">新增关键字</el-button>
            </el-tab-pane>
          </el-tabs>
          <h2>全部试题</h2>
          <el-form-item>
            <el-tabs v-model="allQuestion" tab-position="left">
              <el-tab-pane label="题号" name="serial_number" lazy>
                <div style="display: flex;margin-bottom: 15px;width: 90%;">
                  <FbdEditor
                    v-if="
                      class_cfg_list_all_questionConfig &&
                      class_cfg_list_all_questionConfig.serial_number &&
                      class_cfg_list_all_questionConfig.serial_number.fbd &&
                      class_cfg_list_all_questionConfig.serial_number.fbd.enable_fbd
                    "
                    v-model="
                      class_cfg_list_all_questionConfig.serial_number.fbd
                        .code_template
                    "
                    style="display: inline-block;width: 400px;margin-right: 15px;flex: 1"
                    :options="{ height: editorHeight, removeButtons: removeButtonsQuestionNumber }"
                  />
                  <div style="display: flex; flex-direction: column">
                    <el-checkbox v-model="class_cfg_list_all_questionConfig.serial_number.fbd.is_zk_for_level_1">一级题是否字空</el-checkbox>
                    <el-checkbox v-model="class_cfg_list_all_questionConfig.serial_number.fbd.is_zk_for_level_2">二级题是否字空</el-checkbox>
                    <el-checkbox v-model="class_cfg_list_all_questionConfig.serial_number.global_zk">使用全局 ZK</el-checkbox>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="题号（中文）" name="serial_number_cn" lazy>
                <div style="display: flex;margin-bottom: 15px;width: 90%;">
                  <FbdEditor
                    v-if="
                      class_cfg_list_all_questionConfig &&
                      class_cfg_list_all_questionConfig.serial_number_cn &&
                      class_cfg_list_all_questionConfig.serial_number_cn.fbd
                      "
                    v-model="
                      class_cfg_list_all_questionConfig.serial_number_cn.fbd.code_template
                    "
                    style="display: inline-block;width: 400px;margin-right: 15px;flex: 1"
                    :options="{ height: editorHeight, removeButtons: removeButtonsQuestionNumber }"
                  />
                  <!-- <el-checkbox v-model="class_cfg_list_all_questionConfig.serial_number.fbd.is_zk_for_level_2">二级题是否字空</el-checkbox> -->
                </div>
              </el-tab-pane>
              <el-tab-pane label="来源" name="source" lazy>
                <FbdEditor
                  v-if="
                    class_cfg_list_all_questionConfig &&
                    class_cfg_list_all_questionConfig.source &&
                    class_cfg_list_all_questionConfig.source.fbd &&
                    class_cfg_list_all_questionConfig.source.fbd.enable_fbd
                  "
                  v-model="
                    class_cfg_list_all_questionConfig.source.fbd
                      .code_template
                  "
                  :options="{ height: editorHeight, removeButtons: removeButtonsQuestion }"
                />
              </el-tab-pane>
              <el-tab-pane label="题干" name="body" lazy>
                <FbdEditor
                  v-if="
                    class_cfg_list_all_questionConfig &&
                    class_cfg_list_all_questionConfig.body &&
                    class_cfg_list_all_questionConfig.body.fbd &&
                    class_cfg_list_all_questionConfig.body.fbd.enable_fbd
                  "
                  v-model="
                    class_cfg_list_all_questionConfig.body.fbd.code_template
                  "
                  :options="{ height: editorHeight, removeButtons: removeButtonsQuestion }"
                />
              </el-tab-pane>
              <el-tab-pane label="答案" name="answer" lazy>
                <FbdEditor
                  v-if="
                    class_cfg_list_all_questionConfig &&
                    class_cfg_list_all_questionConfig.answer &&
                    class_cfg_list_all_questionConfig.answer.fbd &&
                    class_cfg_list_all_questionConfig.answer.fbd.enable_fbd
                  "
                  v-model="
                    class_cfg_list_all_questionConfig.answer.fbd.code_template
                  "
                  :options="{ height: editorHeight, removeButtons: removeButtonsQuestion }"
                />
              </el-tab-pane>
              <el-tab-pane label="解析" name="analysis" lazy>
                <FbdEditor
                  v-if="
                    class_cfg_list_all_questionConfig &&
                    class_cfg_list_all_questionConfig.analysis &&
                    class_cfg_list_all_questionConfig.analysis.fbd &&
                    class_cfg_list_all_questionConfig.analysis.fbd.enable_fbd
                  "
                  v-model="
                    class_cfg_list_all_questionConfig.analysis.fbd.code_template
                  "
                  :options="{ height: editorHeight, removeButtons: removeButtonsQuestion }"
                />
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
          <h2>全部选择题</h2>
          <el-form-item>
            <el-tabs v-model="allChoiseQuestion" tab-position="left">
              <!-- <el-tab-pane label="题号" name="serial_number" lazy>
                <FbdEditor
                  v-if="
                    class_cfg_list_all_choiseQuestionConfig &&
                    class_cfg_list_all_choiseQuestionConfig.serial_number &&
                    class_cfg_list_all_choiseQuestionConfig.serial_number.fbd
                      .enable_fbd
                  "
                  v-model="
                    class_cfg_list_all_choiseQuestionConfig.serial_number.fbd
                      .code_template
                  "
                  :options="{ height: 150, removeButtons: removeButtonsQuestion }"
                />
              </el-tab-pane>
              <el-tab-pane label="题干" name="body" lazy>
                <FbdEditor
                  v-if="
                    class_cfg_list_all_choiseQuestionConfig &&
                    class_cfg_list_all_choiseQuestionConfig.body &&
                    class_cfg_list_all_choiseQuestionConfig.body.fbd &&
                    class_cfg_list_all_choiseQuestionConfig.body.fbd.enable_fbd
                  "
                  v-model="
                    class_cfg_list_all_choiseQuestionConfig.body.fbd.code_template
                  "
                  :options="{ height: 150, removeButtons: removeButtonsQuestion }"
                />
              </el-tab-pane> -->
              <el-tab-pane label="选项" name="choices" lazy>
                <div style="display: flex;margin-bottom: 15px;width: 90%;">
                  <FbdEditor
                    v-if="
                      class_cfg_list_all_choiseQuestionConfig &&
                      class_cfg_list_all_choiseQuestionConfig.choices &&
                      class_cfg_list_all_choiseQuestionConfig.choices.fbd &&
                      class_cfg_list_all_choiseQuestionConfig.choices.fbd.enable_fbd
                    "
                    v-model="
                      class_cfg_list_all_choiseQuestionConfig.choices.fbd
                        .code_template
                    "
                    style="display: inline-block;width: 400px;margin-right: 15px;flex: 1"
                    :options="{ height: 150, removeButtons: removeButtonsQuestion }"
                  />
                  <div style="display: flex; flex-direction: column">
                    <el-checkbox v-model="class_cfg_list_all_choiseQuestionConfig.choices.choices_dw_indent">选项使用 DW 缩进</el-checkbox>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="选项字母" name="choice_letter" lazy>
                <FbdEditor
                  v-if="
                    class_cfg_list_all_choiseQuestionConfig &&
                    class_cfg_list_all_choiseQuestionConfig.choice_letter &&
                    class_cfg_list_all_choiseQuestionConfig.choice_letter.fbd
                      .enable_fbd
                  "
                  v-model="
                    class_cfg_list_all_choiseQuestionConfig.choice_letter.fbd
                      .code_template
                  "
                  :options="{ height: 150, removeButtons: removeButtonsQuestion }"
                />
              </el-tab-pane>
              <el-tab-pane label="括号配置" name="bracket" lazy>
                <FbdEditor
                  v-if="
                    class_cfg_list_all_choiseQuestionConfig &&
                    class_cfg_list_all_choiseQuestionConfig.bracket &&
                    class_cfg_list_all_choiseQuestionConfig.bracket.fbd &&
                    class_cfg_list_all_choiseQuestionConfig.bracket.fbd.enable_fbd
                  "
                  v-model="
                    class_cfg_list_all_choiseQuestionConfig.bracket.fbd
                      .code_template
                  "
                  :options="{ height: 150, removeButtons: removeButtonsQuestion }"
                />
              </el-tab-pane>
              <el-tab-pane label="样式配置" name="style_config" lazy>
                <div style="padding :5px; border: 1px solid #d1d1d1;min-height: 200px">
                  <div>
                    选项仅有图片的选项字母位置: <el-select v-model="class_cfg_list_all_choiseQuestionConfig.choices.image_option_letter_position">
                    <el-option label="左边" value="left" />
                    <el-option label="下边" value="bottom" />
                  </el-select>
                  </div>
                  <div style="margin-top: 10px;">
                    选项固定一行:
                    <el-select clearable style=" width: 100px" v-model="class_cfg_list_all_choiseQuestionConfig.choices.choice_per_line">
                      <el-option
                        v-for="item in [
                          {label: '1', value: 1},
                          {label: '2', value: 2},
                          {label: '3', value: 3},
                          {label: '4', value: 4},
                        ]"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select> 个
                  </div>
                </div>
              </el-tab-pane>
              <!-- <el-tab-pane label="答案" name="answer" lazy>
                <FbdEditor
                  v-if="
                    class_cfg_list_all_choiseQuestionConfig &&
                    class_cfg_list_all_choiseQuestionConfig.answer &&
                    class_cfg_list_all_choiseQuestionConfig.answer.fbd &&
                    class_cfg_list_all_choiseQuestionConfig.answer.fbd.enable_fbd
                  "
                  v-model="
                    class_cfg_list_all_choiseQuestionConfig.answer.fbd
                      .code_template
                  "
                  :options="{ height: 150, removeButtons: removeButtonsQuestion }"
                />
              </el-tab-pane>
              <el-tab-pane label="解析" name="analysis" lazy>
                <FbdEditor
                  v-if="
                    class_cfg_list_all_choiseQuestionConfig &&
                    class_cfg_list_all_choiseQuestionConfig.analysis &&
                    class_cfg_list_all_choiseQuestionConfig.analysis.fbd
                      .enable_fbd
                  "
                  v-model="
                    class_cfg_list_all_choiseQuestionConfig.analysis.fbd
                      .code_template
                  "
                  :options="{ height: 150, removeButtons: removeButtonsQuestion }"
                />
              </el-tab-pane> -->
            </el-tabs>
          </el-form-item>
          <h2 v-if="selectedType !== '教材'">语义配置</h2>
          <el-form-item v-if="selectedType !== '教材'">
            <el-tabs v-model="semic_cfg_select" tab-position="left">
              <el-tab-pane label="主标题" name="main_title" lazy>
                <FbdEditor
                  v-if="
                    partial_cfg &&
                    partial_cfg.semic_cfg
                  "
                  v-model="partial_cfg.semic_cfg.main_title"
                  :options="{ height: editorHeight, removeButtons: removeButtonsChapter }"
                />
              </el-tab-pane>
              <el-tab-pane label="小标题" name="sub_title" lazy>
                <FbdEditor
                  v-if="
                    partial_cfg &&
                    partial_cfg.semic_cfg
                  "
                  v-model="partial_cfg.semic_cfg.sub_title"
                  :options="{ height: editorHeight, removeButtons: removeButtonsChapter }"
                />
              </el-tab-pane>
              <el-tab-pane label="试卷描述" name="exam_desc" lazy>
                <FbdEditor
                  v-if="
                    partial_cfg &&
                    partial_cfg.semic_cfg
                  "
                  v-model="partial_cfg.semic_cfg.exam_desc"
                  :options="{ height: editorHeight, removeButtons: removeButtonsChapter }"
                />
              </el-tab-pane>
              <el-tab-pane label="试题描述" name="quest_desc" lazy>
                <FbdEditor
                  v-if="
                    partial_cfg &&
                    partial_cfg.semic_cfg
                  "
                  v-model="partial_cfg.semic_cfg.quest_desc"
                  :options="{ height: editorHeight, removeButtons: removeButtonsChapter }"
                />
              </el-tab-pane>
              <el-tab-pane label="散文标题" name="essay_title" lazy>
                <FbdEditor
                  v-if="
                    partial_cfg &&
                    partial_cfg.semic_cfg
                  "
                  v-model="partial_cfg.semic_cfg.essay_title"
                  :options="{ height: editorHeight, removeButtons: removeButtonsChapter }"
                />
              </el-tab-pane>
              <el-tab-pane label="固定类标题" name="fix_title" lazy>
                <FbdEditor
                  v-if="
                    partial_cfg &&
                    partial_cfg.semic_cfg
                  "
                  v-model="partial_cfg.semic_cfg.fix_title"
                  :options="{ height: editorHeight, removeButtons: removeButtonsChapter }"
                />
              </el-tab-pane>
              <el-tab-pane label="知识点标题" name="knowledge_title" lazy>
                <FbdEditor
                  v-if="
                    partial_cfg &&
                    partial_cfg.semic_cfg
                  "
                  v-model="partial_cfg.semic_cfg.knowledge_title"
                  :options="{ height: editorHeight, removeButtons: removeButtonsChapter }"
                />
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
          <h2>其他配置</h2>
          <el-form-item>
            <el-tabs v-model="other_select" tab-position="left">
              <el-tab-pane label="表格标题" name="table_title" lazy>
                <FbdEditor
                  v-if="
                    partial_cfg &&
                    partial_cfg.table_font
                  "
                  v-model="partial_cfg.table_font.table_title.code_template"
                  :options="{ height: editorHeight, removeButtons: removeForTableTitle }"
                />
              </el-tab-pane>
              <el-tab-pane label="续表" name="continued_table" lazy>
                <FbdEditor
                  v-if="
                    partial_cfg &&
                    partial_cfg.table_font
                  "
                  v-model="partial_cfg.table_font.continued_table.code_template"
                  :options="{ height: editorHeight, removeButtons: removeButtonsAll }"
                />
              </el-tab-pane>
              <el-tab-pane label="单行单列表" name="single_table_cfg" lazy>
                <el-checkbox v-model="partial_cfg.table_font.single_table_cfg.is_convert_fk">是否转为方框</el-checkbox>
                <div v-if="partial_cfg.table_font.single_table_cfg.is_convert_fk">
                  方框开弧代码：
                  <FbdEditor
                    v-if="
                      partial_cfg &&
                      partial_cfg.table_font
                    "
                    v-model="partial_cfg.table_font.single_table_cfg.fk_start.code_template"
                    :options="{ height: 30, removeButtons: removeButtonsAll }"
                  />
                  方框闭弧代码：
                  <FbdEditor
                    v-if="
                      partial_cfg &&
                      partial_cfg.table_font
                    "
                    v-model="partial_cfg.table_font.single_table_cfg.fk_end.code_template"
                    :options="{ height: 30, removeButtons: removeButtonsAll }"
                  />
                </div>
              </el-tab-pane>
              <el-tab-pane label="公式配置" name="formula_cfg" lazy>
                <el-checkbox v-model="partial_cfg.formula_cfg.jb_use_gt_2">JB 占两行时，是否使用 JB &lt; 2 写法</el-checkbox>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
        </div>
        <h2>附加功能</h2>
        <el-form-item>
          <!-- <el-checkbox-group v-model="checkList"> -->
            <!-- <el-checkbox v-model="extra_cfg.latex_autofix" label="latex_autofix">正斜体自动修复</el-checkbox>
            <el-checkbox v-if="selectedType !== '期刊'" v-model="extra_cfg.choise_auto_layout" label="choise_auto_layout">选择题自动布局</el-checkbox>
            <el-checkbox v-model="extra_cfg.img_auto_layout" label="img_auto_layout">图片自动布局</el-checkbox>
            <el-checkbox v-model="extra_cfg.img_auto_covert" label="img_auto_covert">图片自动识别</el-checkbox> -->
            <div style="height: 350px; padding: 15px; border: 1px solid rgb(221, 221, 221)">
              <div class="m-b-10">
                <h2 style="font-weight: 700; font-size: 16px; margin: 0px; display: inline-block;min-width: 120px">图片名称前缀</h2>
                <el-input style="display: inline-block; width: 20%;" v-model="extra_cfg.image_name_prefix" placeholder="图片名称前缀"></el-input>
                <h2 style="font-weight: 700; font-size: 16px; margin: 0 0 0 20px; margin-left: 35px;display: inline-block;min-width: 100px">格式化缩进</h2>
                <el-select clearable v-model="extra_cfg.indent_format">
                  <el-option
                    v-for="item in [
                      {label: '统一增加', value: 'add'},
                      {label: '统一删除', value: 'delete'},
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <h2 style="font-weight: 700; font-size: 16px; margin: 0 0 0 20px;display: inline-block;min-width: 120px">加粗文本渲染为</h2>
                <el-select v-model="extra_cfg.bold_repl_cx_hth">
                  <el-option
                    v-for="item in [
                      {label: 'CX注解', value: 'cx'},
                      {label: 'HT注解', value: 'hth'},
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="m-b-10" >
                <h2 style="font-weight: 700; font-size: 16px; margin: 0; display: inline-block;min-width: 120px">千分位配置</h2>
                <el-select v-model="extra_cfg.thousandth_space">
                  <el-option
                    v-for="item in [
                      {label: '无', value: 'none'},
                      {label: '三分之一空', value: 'third'},
                      {label: '逗号', value: 'comma'},
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <h2 style="font-weight: 700; font-size: 16px; margin: 0; display: inline-block;min-width: 120px">下划线方正代码</h2>
                <el-select v-model="extra_cfg.blank_fbd_code">
                  <el-option
                    v-for="item in [
                      {label: 'ZZ 注解', value: 'zz'},
                      {label: 'CD 注解', value: 'cd'},
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <el-checkbox
                  v-model="extra_cfg.global_wtbx_li"
                  class="checkbox-width m-l-30"
                  >
                  <span style="font-weight: 700; font-size: 16px; color: #293a5c;">理科是否使用全局外体白斜</span>
                </el-checkbox
                >
                <el-checkbox
                  v-model="extra_cfg.refresh_zx"
                  class="checkbox-width"
                  style="margin-left: 110px;"
                  >
                  <span style="font-weight: 700; font-size: 16px; color: #293a5c;">是否全局刷正斜体</span>
                </el-checkbox
                >
              </div>
              <div class="p-b-10" style="border-bottom: 1px solid rgb(221, 221, 221);">
                <h2 style="font-weight: 700; font-size: 16px; margin: 0px; display: inline-block;min-width: 120px">下划线固定长度</h2>
                <el-input type="number" v-model.number="extra_cfg.blank_fixed_length.first" size="mini" style="width: 80px; margin-right: 5px"></el-input>
                <el-input type="number" v-model.number="extra_cfg.blank_fixed_length.second" size="mini" style="width: 80px"></el-input>
              </div>
              <div>
                <h2 style="font-weight: 700; font-size: 16px; margin: 10px 0 5px 0;display: inline-block;min-width: 160px">勾选忽略word样式</h2>
                <el-checkbox v-model="extra_cfg.ignore_word_style_cfg.bold">加粗</el-checkbox>
                <el-checkbox v-model="extra_cfg.ignore_word_style_cfg.color" class="checkbox-width">字色</el-checkbox>
              </div>
              <div class="flex checkBox-container">
                <div class="flex-column" style="min-width: 160px">
                  <h2
                    class="flex-start"
                    style="font-weight: 700; font-size: 16px; margin: 0 0 5px 0; min-width: 140px"
                  >
                    基础配置
                  </h2>
                  <div class="fs-12" style="color: #909399; margin-bottom: 10px">采用文档原有配置</div>
                  <!-- <el-checkbox
                    v-model="group[0]"
                    :indeterminate="isIndeterminate[0]"
                    @change="val => handleChangeGroup(val, 1)"
                    class="checkbox-width text-blue"
                    >全选该列</el-checkbox
                  > -->
                  <el-checkbox v-model="page_cfg.global_column_fbd.is_use_word_fl" class="checkbox-width">保留文档分栏</el-checkbox>
                  <el-checkbox
                    v-model="extra_cfg.extract_annotation"
                    class="checkbox-width"
                    >提取批注</el-checkbox
                  >
                </div>
                <div class="flex-column;" style="flex: 1">
                  <h2
                    class="flex-start"
                    style="font-weight: 700; font-size: 16px; margin: 0 0 5px 0; min-width: 140px"
                  >
                    增强配置
                  </h2>
                  <div class="fs-12" style="color: #909399; margin-bottom: 10px">
                    基础版只支持内容级别转化，高版本样式渲染请联系运营同事
                  </div>
                  <div class="flex">
                    <div style="width: 45%; margin-right: 20px">
                      <!-- <el-checkbox
                        v-model="group[1]"
                        :indeterminate="isIndeterminate[1]"
                        @change="val => handleChangeGroup(val, 2)"
                        class="checkbox-width text-blue"
                        >全选该列</el-checkbox
                      > -->
                      <div class="flex-between" style="width: 70%">
                        <el-checkbox v-model="extra_cfg.formula_compatibility_up" class="checkbox-width"
                          >公式兼容增强</el-checkbox
                        >
                        <el-checkbox v-model="extra_cfg.zx_asssist" class="checkbox-width">
                          <!-- <el-tooltip content="" placement="bottom" effect="light">
                            <span></span>
                          </el-tooltip> -->
                          正斜体辅助
                        </el-checkbox>
                      </div>
                      <div class="flex-between" style="width: 70%">
                        <span style="font-size: 13px; display: inline-block; margin: 0 0 7px 24px"
                          >公式图像识别</span
                        >
                        <el-checkbox
                          v-model="extra_cfg.formula_autofix"
                          class="checkbox-width"
                        >
                          <!-- <el-tooltip
                            content="对一些特定错误写法公式进行准确输出"
                            placement="bottom"
                            effect="light"
                          >
                            <span></span>
                          </el-tooltip> -->
                          公式纠错</el-checkbox
                        >
                      </div>
                      <el-radio style="margin-right: 15px" v-model="extra_cfg.formula_img_recognition" :label="false"
                        >标准版</el-radio
                      >
                      <el-radio
                        v-model="extra_cfg.formula_img_recognition"
                        :label="true"
                        >高精准版</el-radio
                      >
                    </div>
                    <div style="width: 50%" class="flex-between">
                      <div class="flex-column">
                        <!-- <el-checkbox
                          v-model="group[2]"
                          :indeterminate="isIndeterminate[2]"
                          @change="val => handleChangeGroup(val, 3)"
                          class="checkbox-width text-blue"
                          >全选该列</el-checkbox
                        > -->
                        <el-checkbox v-model="extra_cfg.choise_auto_layout" class="checkbox-width">
                          <!-- <el-tooltip content="对公式进行基础的正斜体纠错" placement="bottom" effect="light">
                            <span></span>
                          </el-tooltip> -->
                          选项布局
                        </el-checkbox>
                        <el-checkbox
                          v-model="extra_cfg.auto_zk"
                          class="checkbox-width"
                        >
                          <!-- <el-tooltip content="针对试题类内容进行 ZK 优化" placement="top" effect="light">
                            <span></span>
                          </el-tooltip> -->
                          自动字空
                        </el-checkbox>
                        <el-checkbox
                          v-model="extra_cfg.image_auto_layout"
                          class="checkbox-width"
                        >
                          <!-- <el-tooltip content="图文布局优化" placement="top" effect="light">
                            <span></span>
                          </el-tooltip> -->
                          图文布局优化
                        </el-checkbox>
                      </div>
                      <div style="width: 45%">
                        <!-- <el-checkbox
                          v-model="group[3]"
                          :indeterminate="isIndeterminate[3]"
                          @change="val => handleChangeGroup(val, 4)"
                          class="checkbox-width text-blue"
                          >全选该列</el-checkbox
                        > -->
                        <el-checkbox v-model="extra_cfg.image_to_gray" class="checkbox-width">
                          <!-- <el-tooltip content="对彩色图像输出灰度转化结果" placement="top" effect="light">
                            <span></span>
                          </el-tooltip> -->
                          输出灰度图
                        </el-checkbox>
                        <el-checkbox class="checkbox-width" v-model="extra_cfg.complex_table">
                          <!-- <el-tooltip content="输出优化过的复杂表格结果" placement="bottom" effect="light">
                            <span></span>
                          </el-tooltip> -->
                          复杂表格
                        </el-checkbox>
                        <el-checkbox
                          class="checkbox-width"
                          v-model="extra_cfg.image_auto_recognition"
                        >
                          <!-- <el-tooltip
                            content="对试题截图进行完整的文本、公式、图片转化输出"
                            placement="bottom"
                            effect="light"
                          >
                            <span></span>
                          </el-tooltip> -->
                          截图试题识别
                        </el-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <!-- </el-checkbox-group> -->
        </el-form-item>
        <div v-if="selectedType === '期刊'">
          <h2>期刊配置</h2>
          <el-tabs v-model="journal_cfg_select" tab-position="left">
            <template v-for="(item) in Object.keys(journal_cfg)">
              <el-tab-pane :key="item" :label="journal_cfg_label[item]" :name="item" lazy>
                <div :key="item" style="display: flex;margin-bottom: 15px;width: 90%;">
                  <FbdEditor
                    v-model="
                      journal_cfg[item].code_template
                    "
                    style="display: inline-block;width: 400px;margin-right: 15px;flex: 1"
                    :options="{ height: 500, removeButtons: removeButtonsAll }"
                  />
                </div>
              </el-tab-pane>
            </template>
          </el-tabs>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import HeaderBar from './components/HeaderBar';
// import { BookRender } from 'book-preview';
// import {
//   defaultQuestion, defaultChapter, defaultParagraph, defaultColumn, defaultGroupStyle, getDefaultDecoration, getDefaultDynamicDecoration
//   , defaultMargin, defualtPageNumber, defaultMarginImg, defaultMarginText, defaultKeyWord, defaultext_cfg
// } from '@/scripts/styleToolDefault';
// import { removeIfEmpty, mergePeer } from '@/scripts/helper';

import _ from 'lodash';
// import { catalog } from './assets/componentCatalog.js';
import { defaultStyle, defaultJournalCfg, defaultSemicCfg } from './assets/defaultStyle.js';
import FormatInput from './components/FormatInput';
import BaseStyle from './components/BaseStyle';
import { getStyleConfig, creatStyle, editStyle, getStyleInfo } from '@/api/Allservices';

import editor from 'hexin-ckeditor-doc-vue/dist/editor.umd.js';
import 'hexin-ckeditor-doc-vue/dist/editor.css';
const FbdEditor = editor.FbdEditor;

export default {
  name: 'style-demo',
  props: {
    mode: {
      type: String,
      required: false
    },
    config: {
      type: Object,
      required: false
    }
  },
  components: {
    HeaderBar,
    FormatInput,
    FbdEditor,
    BaseStyle,
  },
  computed: {
    org () {
      return this.$store.state.user?.orgs[0]?.orgId || 0;
    },

    selectedType () {
      const { type } = this.styleConfig;
      const key = Object.keys(this.types).find(k => this.types[k] === type);
      return key || '请选择';
    },

    selectedSize () {
      const { size } = this.styleConfig;
      const key = Object.keys(this.size).find(k => this.size[k] === size);
      return key || '请选择';
    },
    components () {
      return this.styleData?.components?.[0] || {};
    },
    margin_components () {
      return this.components?.margin_components || [];
    },
    // 页眉
    margin_components_header_page () {
      return (
        this.margin_components?.find((item) => item.margin_type === 'header' && item.page_selector === 'page') ||
        {}
      );
    },
    margin_components_header_odd () {
      return (
        this.margin_components?.find((item) => item.margin_type === 'header' && item.page_selector === 'odd') ||
        {}
      );
    },
    margin_components_header_even () {
      return (
        this.margin_components?.find((item) => item.margin_type === 'header' && item.page_selector === 'even') ||
        {}
      );
    },
    // 页脚
    margin_components_footer () {
      return (
        this.margin_components?.find((item) => item.margin_type === 'footer') ||
        {}
      );
    },
    // 左边栏
    margin_components_left () {
      return (
        this.margin_components?.find((item) => item.margin_type === 'left') ||
        {}
      );
    },
    // 右边栏
    margin_components_right () {
      return (
        this.margin_components?.find((item) => item.margin_type === 'right') ||
        {}
      );
    },

    // class_cfg_list
    class_cfg_list () {
      return this.components?.class_cfg_list || [];
    },
    class_cfg_list_chapter_list_all () {
      return (
        this.class_cfg_list?.filter(
          (item) =>
            item.identity?.node_type === 'chapter'
        ) || []
      );
    },
    chapter_section_list () {
      const list = [];
      this.class_cfg_list_chapter_list_all.map(item => {
        if (item.section_name && !list.includes(item.section_name)) {
          list.push(item.section_name);
        }
      });
      return list;
    },
    class_cfg_list_chapter_list () {
      return (
        this.class_cfg_list?.filter(
          (item) =>
            item.identity?.node_type === 'chapter' && item.section_name === this.active_section_name
        ) || []
      );
    },
    // 弃用下面
    class_cfg_list_chapter_level1 () {
      return (
        this.class_cfg_list?.find(
          (item) =>
            item.identity?.node_type === 'chapter' &&
            item.identity?.content?.level === 1
        ) || {}
      );
    },
    class_cfg_list_chapter_level2 () {
      return (
        this.class_cfg_list?.find(
          (item) =>
            item.identity?.node_type === 'chapter' &&
            item.identity?.content?.level === 2
        ) || {}
      );
    },
    class_cfg_list_chapter_level3 () {
      return (
        this.class_cfg_list?.find(
          (item) =>
            item.identity?.node_type === 'chapter' &&
            item.identity?.content?.level === 3
        ) || {}
      );
    },
    class_cfg_list_all_questionConfig () {
      return (
        this.class_cfg_list?.find(
          (item) =>
            item.identity?.node_type === 'question' &&
            !item.identity.content?.question_type &&
            !item.identity.content?.level &&
            !item.identity.tag_key
        )?.config || {}
      );
    },
    class_cfg_list_all_choiseQuestionConfig () {
      return (
        this.class_cfg_list?.find(
          (item) =>
            item.identity.node_type === 'question' &&
            item.identity.content?.question_type === 'choice'
        )?.config || {}
      );
    },

    // 版本
    output_cfg () {
      return this.components?.output_cfg || {};
    },

    // 版心
    page_cfg () {
      return this.components?.page_cfg || {};
    },

    computedPageCoreSize () {
      let {
        page_size: { width, height },
      } = this.page_cfg;
      width = parseFloat(width);
      height = parseFloat(height);
      return [
        { width: width - 6, height: height - 6, name: '小' },
        { width: width - 10, height: height - 10, name: '中' },
        { width: width - 20, height: height - 20, name: '标准' },
        { width: width - 30, height: height - 30, name: '大' },
        { width: width - 40, height: height - 40, name: '特大' },
        { name: '自定义' },
      ];
    },

    computedPageSizes () {
      const copyData = [...this.pageSizes];
      return copyData;
    },

    // 文字
    globalColor () {
      const { book_default_style: { global_color } } = this.styleData;
      if (!global_color) return [];
      return global_color;
    },

    // 局部
    partial_cfg () {
      return this.components?.partial_cfg || {};
    },

    // 期刊配置
    journal_cfg () {
      return this.components?.journal_cfg || {};
    },

    // 附加
    extra_cfg () {
      return this.components?.extra_cfg || {};
    },

    customer_id () {
      return Number(this.$route.query.customer_id) || this.org;
    }
  },
  data () {
    return {
      loading: false,

      styleData: null,

      allChapterConfig: 'xml-keyword',
      allNormalConfig: 'global_column_fbd',
      allQuestion: 'serial_number',
      allChoiseQuestion: 'choices',

      // 版心
      actPageSize: 'A4',
      actPageCoreSize: '标准',
      pageSizes: [
        { height: '297', width: '420', name: 'A3' },
        { height: '297', width: '210', name: 'A4' },
        { height: '353', width: '250', name: 'B4' },
        { height: '250', width: '176', name: 'B5' },
        { height: '390', width: '271', name: '8开' },
        { height: '374', width: '262', name: '大8开' },
        { height: '271', width: '195', name: '16开' },
        { height: '285', width: '210', name: '大16开' },
        { height: '195', width: '135', name: '32开' },
        { height: '203', width: '140', name: '大32开' },
        { height: '135', width: '97', name: '64开' },
        { name: '自定义' },
      ],

      // 文字样式
      globalTextValue: null,
      color: {
        cmyk: [],
        rgb: []
      },
      // 局部
      tempTextList: [],

      // editor
      editorHeight: 100,
      removeButtons: 'fbd_table_title,fbd_chapter,fbd_keyword,document,Source,fbd_serial_number,fbd_body,fbd_choices,fbd_choice_letter,fbd_bracket,fbd_answer,fbd_analysis,fbd_source,xml_fbd_keyword,fbd_remain',
      removeButtonsPageHeader: 'fbd_table_title,fbd_keyword,document,Source,fbd_serial_number,fbd_body,fbd_choices,fbd_choice_letter,fbd_bracket,fbd_answer,fbd_analysis,fbd_source,xml_fbd_keyword,fbd_remain',
      removeButtonsChapter: 'fbd_table_title,fbd_keyword,document,Source,fbd_serial_number,fbd_body,fbd_choices,fbd_choice_letter,fbd_bracket,fbd_answer,fbd_analysis,xml_fbd_keyword,fbd_source,fbd_remain',
      removeForTableTitle: 'fbd_chapter,fbd_image,fbd_keyword,document,Source,fbd_serial_number,fbd_body,fbd_choices,fbd_choice_letter,fbd_bracket,fbd_answer,fbd_analysis,xml_fbd_keyword,fbd_source,fbd_remain',
      removeXmlKeyWord: 'fbd_table_title,fbd_chapter,fbd_keyword,document,Source,fbd_serial_number,fbd_body,fbd_choices,fbd_choice_letter,fbd_bracket,fbd_answer,fbd_analysis,fbd_source',
      removeButtonsQuestion: 'fbd_table_title,fbd_image,fbd_keyword,document,Source,fbd_chapter,xml_fbd_keyword,fbd_remain',
      removeButtonsQuestionNumber: 'fbd_table_title,fbd_keyword,document,Source,fbd_chapter,xml_fbd_keyword,fbd_remain',
      removeButtonsAll: 'fbd_table_title,fbd_image,fbd_chapter,fbd_keyword,document,Source,fbd_serial_number,fbd_body,fbd_choices,fbd_choice_letter,fbd_bracket,fbd_answer,fbd_analysis,fbd_source,xml_fbd_keyword,fbd_remain',

      // 附加选项
      checkList: [],

      styleConfig: {},

      types: {},

      size: {},

      journal_cfg_select: 'doi_number',
      semic_cfg_select: 'main_title',

      other_select: 'table_title',

      journal_cfg_label: {
        doi_number: '稿件编号',
        title_cn: '中文标题',
        author_cn: '中文作者',
        address_cn: '中文地址',
        abstract_cn: '中文摘要',
        keywords_cn: '中文关键词',
        clc_number: '中图分类号',
        essay_code: '文献标志码',
        essay_number: '文章编号',
        footnote: '稿件信息',
        title_en: '英文标题',
        author_en: '英文作者',
        address_en: '英文地址',
        abstract_en: '英文摘要',
        keywords_en: '英文关键词',
        essay_title_1: '一级标题',
        essay_title_2: '二级标题',
        essay_title_3: '三级标题',
        essay_title_4: '四级标题',
        essay_table: '表题',
        essay_chart: '图题',
        ref_title: '文献标题',
        ref_list: '文献列表',
        abstract_cn_content: '中文摘要内容',
        abstract_en_content: '英文摘要内容',
        keywords_cn_content: '中文关键词内容',
        keywords_en_content: '英文关键词内容',
        footnote_content: '稿件信息内容',
      },

      // 页眉
      pageHeaderRadio: false,

      // 当前标题组
      active_section_name: '',
      active_section_name_tmp: '',
      edit_section_name: '',
      active_chapter_tap: 'class_cfg_list_chapter_level1',

      labelWidth: '70px',
    };
  },
  watch: {
    styleData: {
      deep: true,
      handler (val) {
        console.log(val);
      },
    },
  },
  async created () {
    // TODO 改传递
    await this.getStyleInfo();
    if (this.$route.query.style_id) {
      const styleConfig = await getStyleConfig({
        style_id: this.$route.query.style_id
      });
      this.styleConfig = styleConfig;
      await this.init(styleConfig.config);
    } else if (this.mode === 'edit' && this.config) {
      this.styleConfig = {
        name: this.config.style_config_name,
        config: this.config.style_config_data,
        type: this.config.word_type,
      };
      await this.init(this.config.style_config_data);
    } else {
      await this.init();
    }
  },
  methods: {
    async init (data) {
      this.loading = true;
      let styleData;
      if (data) {
        // styleData = mergePeer(temp, data);
        // 兼容老数据
        // _.merge(data.components[0].partial_cfg, { keyword_cfg: {} })
        styleData = { ...data };
        if (!styleData.components[0].partial_cfg.keyword_cfg) {
          styleData.components[0].partial_cfg.keyword_cfg = {};
        }
        styleData.components[0].partial_cfg.semic_cfg = Object.assign(defaultSemicCfg, styleData.components[0].partial_cfg.semic_cfg ?? {});
        styleData.components[0].journal_cfg = Object.assign(defaultJournalCfg, styleData.components[0].journal_cfg ?? {});
        if (!styleData.components[0].page_cfg.pro_fbd) {
          styleData.components[0].page_cfg.pro_fbd = {
            code_template: ''
          };
        }
      } else {
        styleData = defaultStyle;
      }

      // fix data start
      // 因为一些历史数据没有水印图的position信息，页面就白屏报错，这里修正一下
      styleData.components[0].margin_components.forEach((item) => {
        if (item.data && item.data.length) {
          item.data.forEach((i) => {
            if (i.node_type === 'image' && !i.position) {
              i.position = {
                x: '0mm',
                y: '0mm',
              };
            }
          });
        }
      });

      // 兼容老数据的标题
      if (styleData.components[0].class_cfg_list.filter(item => item?.identity?.node_type === 'chapter').length) {
        styleData.components[0].class_cfg_list.filter(item => item?.identity?.node_type === 'chapter').map(item => {
          if (!item.section_name) {
            this.$set(item, 'section_name', '默认');
          }
        });
      }

      // 兼容老数据
      if (!styleData.components[0].extra_cfg?.ignore_word_style_cfg) {
        this.$set(styleData.components[0].extra_cfg, 'ignore_word_style_cfg', {
          bold: false,
          color: false,
        });
      }
      if (!styleData.components[0].partial_cfg?.table_font?.simple_table_cfg) {
        this.$set(styleData.components[0].partial_cfg.table_font, 'simple_table_cfg', {
          top_space: '1mm', // 上空，默认写入 1mm
          bottom_space: '1mm', // 下空，默认写入 1mm
        });
      }
      if (!styleData.components[0].partial_cfg?.table_font?.table_title) {
        this.$set(styleData.components[0].partial_cfg.table_font, 'table_title', {
          code_template: ''
        });
      }
      if (!styleData.components[0].partial_cfg?.table_font?.continued_table) {
        this.$set(styleData.components[0].partial_cfg.table_font, 'continued_table', {
          code_template: ''
        });
      }
      if (!styleData.components[0].partial_cfg?.table_font?.single_table_cfg) {
        this.$set(styleData.components[0].partial_cfg.table_font, 'single_table_cfg', {
          is_convert_fk: false, // 单行单列表是否转为方框，默认 false
          fk_start: {
            code_template: '' // 方框开始的三方代码
          },
          fk_end: {
            code_template: '' // 方框结束的三方代码
          },
        });
      }
      if (!styleData.components[0].partial_cfg?.table_font?.del_indent) {
        this.$set(styleData.components[0].partial_cfg.table_font, 'del_indent', false);
      }
      if (!styleData.components[0].partial_cfg?.extra_content) {
        this.$set(styleData.components[0].partial_cfg, 'extra_content', {
          code_template: '', // 三方代码
          location: 'start', // 全文开头 | 一级标题前
          is_in_column: false, // 是否在分栏内
        });
      }
      if (!styleData.components[0].partial_cfg?.wb_cfg) {
        this.$set(styleData.components[0].partial_cfg, 'wb_cfg', {
          '4': { 'fbd': '' },
          '3': { 'fbd': '' },
        });
      }
      if (!styleData.components[0].partial_cfg?.formula_cfg) {
        this.$set(styleData.components[0].partial_cfg, 'formula_cfg', {
          jb_use_gt_2: false, // 公式相关配置-JB 占两行时，是否使用 JB<2 写法
        });
      }
      if (!styleData.components[0].extra_cfg?.image_name_prefix) {
        this.$set(styleData.components[0].extra_cfg, 'image_name_prefix', '');
      }
      if (!styleData.components[0].extra_cfg?.indent_format) {
        this.$set(styleData.components[0].extra_cfg, 'indent_format', '');
      }
      if (!styleData.components[0].extra_cfg?.bold_repl_cx_hth) {
        this.$set(styleData.components[0].extra_cfg, 'bold_repl_cx_hth', 'cx');
      }
      if (!styleData.components[0].extra_cfg?.thousandth_space) {
        this.$set(styleData.components[0].extra_cfg, 'thousandth_space', 'none');
      }
      if (!styleData.components[0].extra_cfg?.blank_fbd_code) {
        this.$set(styleData.components[0].extra_cfg, 'blank_fbd_code', 'zz');
      }
      if (styleData.components[0].extra_cfg?.global_wtbx_li === undefined) {
        this.$set(styleData.components[0].extra_cfg, 'global_wtbx_li', true);
      }
      if (!styleData.components[0].extra_cfg?.blank_fixed_length) {
        this.$set(styleData.components[0].extra_cfg, 'blank_fixed_length', {
          first: 0,
          second: 0,
        });
      }

      styleData.components[0].class_cfg_list.filter(item => item?.identity?.node_type === 'chapter').map(item => {
        if (item.fbd && !item.fbd.fl_template) {
          // todo: 为什么这里要用$set 才能响应
          this.$set(item.fbd, 'is_fl', false);
          this.$set(item.fbd, 'fl_template', '');
        }
      });
      const class_cfg_list_all_questionConfig = styleData.components[0].class_cfg_list.find(item => item?.identity?.node_type === 'question' && !item.identity.content?.question_type && !item.identity.content?.level && !item.identity.tag_key).config;
      if (!class_cfg_list_all_questionConfig.serial_number_cn) {
        this.$set(class_cfg_list_all_questionConfig, 'serial_number_cn', {
          fbd: {
            code_template: '',
          }
        });
      }
      if (class_cfg_list_all_questionConfig.serial_number.fbd.is_zk_for_level_1 === undefined) {
        this.$set(class_cfg_list_all_questionConfig.serial_number.fbd, 'is_zk_for_level_1', true);
      }

      if (!styleData.components[0].class_cfg_list.find(item => item.identity.node_type === 'question' && item.identity.content?.question_type === 'choice').config?.choices?.image_option_letter_position) {
        styleData.components[0].class_cfg_list.find(item => item.identity.node_type === 'question' && item.identity.content?.question_type === 'choice').config.choices.image_option_letter_position = 'left';
      }
      if (!styleData.components[0].class_cfg_list.find(item => item.identity.node_type === 'question' && item.identity.content?.question_type === 'choice').config?.choices?.choice_per_line) {
        styleData.components[0].class_cfg_list.find(item => item.identity.node_type === 'question' && item.identity.content?.question_type === 'choice').config.choices.choice_per_line = '';
      }

      // 左右页眉区分
      if (styleData.components[0].margin_components.find((item) => item.margin_type === 'header' && item.page_selector === 'odd')?.fbd?.code_template) {
        this.pageHeaderRadio = true;
      } else {
        this.pageHeaderRadio = false;
        styleData.components[0].margin_components.push(...[
          {
            margin_type: 'header',
            page_selector: 'odd',
            fbd: {
              enable_fbd: true,
              code_template: '',
              is_follow_chapter: false,
            },
          },
          {
            margin_type: 'header',
            page_selector: 'even',
            fbd: {
              enable_fbd: true,
              code_template: '',
              is_follow_chapter: false,
            }
          }]);
      }

      this.styleData = styleData;

      this.globalTextValue = this.styleData.components[0].default_style;

      this.loading = false;

      const { page_cfg: { page_size: { width }, page_core_size: { width: coreWidth } } } = this;
      this.inputPageSize(parseFloat(width).toString(), 'width');
      this.inputPageCoreSize(parseFloat(coreWidth).toString(), 'width');
    },

    async getStyleInfo () {
      const { type, size } = await getStyleInfo({});
      this.types = type;
      this.size = size;
    },

    async onComplete () {
      if (!this.page_cfg.global_column_fbd.min_column_width) {
        return this.$message.error('请先填写分栏配置中的栏宽属性！');
      }
      if (!this.styleConfig.type && this.mode !== 'edit') {
        return this.$message.error('请填写样式类型！');
      }
      this.loading = true;
      // const data = _.cloneDeep(this.styleData);
      const data = _.cloneDeep(this.styleData);
      // 清除掉没有 node_id_list 存在的批量配置
      const [component] = data.components;
      if (component.node_batch_cfg_list) {
        component.node_batch_cfg_list = component.node_batch_cfg_list.filter(
          (item) => item.node_id_list
        );
      }
      // 通过 pageHeaderRadio 判断是否需要清除左右页眉
      if (this.pageHeaderRadio) {
        const page = component.margin_components.find((item) => item.margin_type === 'header' && item.page_selector === 'page');
        if (page) {
          page.fbd.code_template = '';
        }
      } else {
        const page_odd = component.margin_components.find((item) => item.margin_type === 'header' && (item.page_selector === 'odd'));
        if (page_odd) {
          page_odd.fbd.code_template = '';
        }
        const page_even = component.margin_components.find((item) => item.margin_type === 'header' && (item.page_selector === 'even'));
        if (page_even) {
          page_even.fbd.code_template = '';
        }
      }
      // 若 fbd.enable_fbd 为 false，并且 code_template 值为空，则删掉当前字段
      if (component.margin_components) {
        component.margin_components.forEach((component) => {
          if (
            component.fbd &&
            !component.fbd.enable_fbd &&
            !component.fbd.code_template
          ) {
            delete component.fbd;
          }
        });
      }
      if (this.selectedType === '期刊') {
        delete component.node_cfg_list;
      } else {
        delete component.journal_cfg;
      }
      if (this.$route.query.style_id) {
        await editStyle({
          name: this.styleConfig.name,
          type: this.styleConfig.type,
          size: this.styleConfig.size,
          config: data,
          customer_id: this.customer_id,
          style_id: Number(this.$route.query.style_id)
        });
      } else if (this.mode !== 'edit') {
        await creatStyle({
          name: this.styleConfig.name,
          type: this.styleConfig.type,
          size: this.styleConfig.size,
          config: data,
          customer_id: this.customer_id
        });
      }
      this.loading = false;
      this.$message.success('保存成功');
      if (this.$route.query.style_id) {
        this.$router.push(`/create?id=${this.$route.query.id}&fbd_config_visible=1&reOpen=1${this.$route.query.reEdit ? '&reEdit=' + this.$route.query.reEdit : ''}${this.$route.query.reEdit ? '&search=' + this.styleConfig.name : ''}`);
      } else if (this.mode === 'edit') {
        this.$emit('save', this.styleConfig);
      } else {
        this.$router.push(`/create?id=${this.$route.query.id}&fbd_config_visible=1${this.$route.query.reEdit ? '&reEdit=' + this.$route.query.reEdit : ''}`);
      }
    },

    goBack () {
      if (this.mode === 'edit') {
        this.$emit('back');
      } else {
        this.$router.push(`/create?id=${this.$route.query.id}&fbd_config_visible=1${this.$route.query.reEdit ? '&reEdit=' + this.$route.query.reEdit : ''}`);
      }
    },

    inputPageCoreSize (val, position) {
      const { page_core_size, page_size, page_margin } = this.page_cfg;
      page_core_size[position] = val.replace(/[^0-9\.]/g, '') + 'mm';
      const curPageSize = this.computedPageCoreSize.find(
        (item) =>
          item.width === parseFloat(page_core_size.width) &&
          item.height === parseFloat(page_core_size.height)
      );
      if (curPageSize) {
        this.actPageCoreSize = curPageSize.name;
      } else {
        this.actPageCoreSize = '自定义';
      }
      if (position === 'width') {
        page_margin.left =
          parseFloat(page_size.width) -
          parseFloat(page_core_size.width) -
          parseFloat(page_margin.right) +
          'mm';
      } else {
        page_margin.bottom =
          parseFloat(page_size.height) -
          parseFloat(page_core_size.height) -
          parseFloat(page_margin.top) +
          'mm';
      }
    },

    inputPageSize (val, position) {
      const { page_size } = this.page_cfg;
      page_size[position] = val.replace(/[^0-9\.]/g, '') + 'mm';
      const curPageSize = this.pageSizes.find(item => item.width === parseFloat(page_size.width) && item.height === parseFloat(page_size.height));
      if (curPageSize) {
        this.actPageSize = curPageSize.name;
      } else {
        this.actPageSize = '自定义';
      }
    },

    changePageCoreSize (item) {
      let { width: coreWidth, height: coreHeight, name } = item;
      coreWidth = parseFloat(coreWidth);
      coreHeight = parseFloat(coreHeight);
      this.actPageCoreSize = name;
      if (coreWidth && coreHeight) {
        let {
          page_cfg: {
            page_size: { width, height },
          },
          page_cfg,
        } = this;
        width = parseFloat(width);
        height = parseFloat(height);
        const brow = (height - coreHeight) / 2;
        const sidebar = (width - coreWidth) / 2;
        Object.assign(page_cfg, {
          page_core_size: {
            width: coreWidth + 'mm',
            height: coreHeight + 'mm',
          },
          page_margin: {
            top: brow + 'mm',
            right: sidebar + 'mm',
            bottom: brow + 'mm',
            left: sidebar + 'mm',
          },
        });
      }
    },

    changePageSize (item) {
      const { width, height, name } = item;
      this.actPageSize = name;
      this.actPageCoreSize = '标准';
      if (width && height) {
        const { page_size, page_core_size, page_margin } = this.page_cfg;
        Object.assign(page_size, {
          width: width + 'mm',
          height: height + 'mm',
          name: name
        });
        Object.assign(page_core_size, {
          width: width - 20 + 'mm',
          height: height - 20 + 'mm'
        });
        Object.assign(page_margin, {
          top: '10mm',
          right: '10mm',
          bottom: '10mm',
          left: '10mm'
        });
      }
    },

    addGlobalColor () {
      const { book_default_style: { global_color } } = this.styleData;
      const { cmyk } = this.color;
      if (cmyk.length !== 4 || cmyk.some(item => isNaN(parseFloat(item)))) {
        this.$message.warning('输入CMYK不符合要求');
        return;
      }
      global_color.push(this.color);
      this.color = {
        cmyk: [],
        rgb: []
      };
    },

    inputCmyk (val, position) {
      val = +val.replace(/[^0-9\.]/g, '');
      val = val < 0 ? 0
        : val > 100 ? 100 : val;
      const { cmyk } = this.color;
      cmyk.splice(position, 1, val);
      if (!cmyk || cmyk.length !== 4) return;
      this.color.rgb = this.cmykToRGB(cmyk);
    },
    cmykToRGB ([c, m, y, k]) {
      const r = Math.round(255 * (1 - c / 100) * (1 - k / 100));
      const g = Math.round(255 * (1 - m / 100) * (1 - k / 100));
      const b = Math.round(255 * (1 - y / 100) * (1 - k / 100));
      return [r, g, b];
    },

    addTempTextStyle () {
      this.tempTextList.push({
        font_size: '10.5pt',
        font_family_cn: 'SongTi',
        font_family_en: 'Baizheng',
        font_family_num: 'Baizheng',
        font_color: { rgb: [0, 0, 0] },
        text_align: 'left',
        line_spacing: 0.5,
      });
    },
    deleteTempTextList (index) {
      this.tempTextList.splice(index, 1);
    },
    handleType (command) {
      const { type } = this.$route.query;
      if (type !== command) {
        this.$set(this.styleConfig, 'type', command);
      }
    },
    handleSize (command) {
      const { size } = this.$route.query;
      if (size !== command) {
        this.$set(this.styleConfig, 'size', command);
      }
    },

    addQuestionKeyWord () {
      const keys = Object.keys(this.partial_cfg.keyword_cfg).map(item => +item.match(/\d+/)[0]);
      const indexMax = (_.max(keys) || 0) + 1;
      const newItem = {
        idx: indexMax,
        inline: false,
        is_in_column: false,
        fbd_code: '',
        del_image: false,
      };
      const obj = { ...this.partial_cfg.keyword_cfg, ...{ [`keyword_${indexMax}`]: newItem } };
      this.$set(this.partial_cfg, 'keyword_cfg', obj);
      this.$forceUpdate();
    },

    deleteXmlKeyWord (key) {
      delete this.partial_cfg.keyword_cfg[key];
      this.$forceUpdate();
    },

    getLevelCn (index) {
      return [
        '一',
        '二',
        '三',
        '四',
        '五',
        '六',
        '七',
      ][index];
    },

    addChapterSection () {
      if (!this.active_section_name_tmp) return this.$message.error('请输入章节名称');
      if (this.chapter_section_list.includes(this.active_section_name_tmp)) return this.$message.error('该章节名称已经存在');
      this.active_section_name = this.active_section_name_tmp;
      this.active_chapter_tap = this.active_section_name;
      this.class_cfg_list.push(
        {
          identity: {
            content: {
              level: 1,
            },
            _parent: {},
            node_type: 'chapter',
          },
          fbd: {
            enable_fbd: true,
            code_template: '',
            is_in_column: true,
            is_lm: false,
            is_fl: false,
            fl_template: '',
            need_serial_number: true,
          },
          section_name: this.active_section_name,
        },
        {
          identity: {
            content: {
              level: 2,
            },
            _parent: {},
            node_type: 'chapter',
          },
          fbd: {
            enable_fbd: true,
            code_template: '',
            is_in_column: true,
            is_lm: false,
            is_fl: false,
            fl_template: '',
            need_serial_number: true,
          },
          section_name: this.active_section_name,
        },
        {
          identity: {
            content: {
              level: 3,
            },
            _parent: {},
            node_type: 'chapter',
          },
          fbd: {
            enable_fbd: true,
            code_template: '',
            is_in_column: true,
            is_lm: false,
            is_fl: false,
            fl_template: '',
            need_serial_number: true,
          },
          section_name: this.active_section_name,
        },
      );
      this.allChapterConfig = this.active_section_name;
    },

    addChapter () {
      this.class_cfg_list.push(
        {
          identity: {
            content: {
              level: this.class_cfg_list_chapter_list.length + 1,
            },
            _parent: {},
            node_type: 'chapter',
          },
          fbd: {
            enable_fbd: true,
            code_template: '',
            is_in_column: true,
            is_lm: false,
            is_fl: false,
            fl_template: '',
            need_serial_number: true,
          },
          section_name: this.active_section_name,
        }
      );
    },

    changeChatperSection () {
      this.active_chapter_tap = 'class_cfg_list_chapter_level1';
      this.active_section_name = this.allChapterConfig;
    },

    renameChapter () {
      if (!this.edit_section_name) return this.$message.error('请输入修改成的章节名称！');
      const self = this;
      this.class_cfg_list_chapter_list_all.map(item => {
        if (item.section_name === this.active_section_name) {
          self.$set(item, 'section_name', this.edit_section_name);
        }
      });
      this.active_section_name = this.edit_section_name;
      this.allChapterConfig = this.edit_section_name;
      this.edit_section_name = '';
      this.$forceUpdate();
    },
    async deleteSection () {
      await this.$confirm(`确定删除章节${this.active_section_name}吗？`);
      // 数组中删除section_name是active_section_name的元素
      this.components.class_cfg_list = this.components?.class_cfg_list?.filter(
          (item) =>
            item.identity?.node_type !== 'chapter' || item.section_name !== this.active_section_name
        );
      this.$nextTick(() => {
        this.active_section_name = '';
        this.allChapterConfig = '';
        this.$forceUpdate();
      });
    },

    inputPageMarginSize (val, position) {
      const { page_margin, page_core_size, page_size } = this.page_cfg;
      // page_margin[position] = val.replace(/[^0-9\.]/g, '') + 'mm'
      if (val === '') {
        page_margin[position] = '';
        return;
      }
      page_margin[position] = Math.floor(val * 10) / 10 + 'mm';
      if (position === 'top') {
        page_margin.bottom = parseFloat(page_size.height) - parseFloat(page_core_size.height) - parseFloat(page_margin.top) + 'mm';
      } else if (position === 'bottom') {
        page_margin.top = parseFloat(page_size.height) - parseFloat(page_core_size.height) - parseFloat(page_margin.bottom) + 'mm';
      } else if (position === 'left') {
        page_margin.right = parseFloat(page_size.width) - parseFloat(page_core_size.width) - parseFloat(page_margin.left) + 'mm';
      } else {
        page_margin.left = parseFloat(page_size.width) - parseFloat(page_core_size.width) - parseFloat(page_margin.right) + 'mm';
      }
    },

    // 设置版心居中
    autoSetMargin (mark) {
      const { page_margin, page_core_size, page_size } = this.page_cfg;
      if (mark === 'v') {
        page_margin.top = page_margin.bottom = (parseFloat(page_size.height) - parseFloat(page_core_size.height)) / 2 + 'mm';
      }
      if (mark === 'h') {
        page_margin.left = page_margin.right = (parseFloat(page_size.width) - parseFloat(page_core_size.width)) / 2 + 'mm';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 20210420 改版v2样式demo
@import "./assets/restyle.scss";
.style-demo {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  .body {
    flex: auto;
    overflow: hidden;
    display: flex;

    .menu {
      > * {
        height: 100%;
      }

      &:not(.el-menu--collapse) {
        width: 200px;
        flex: none;
        background-color: #fff;
      }

      .icon {
        margin-right: 5px;
      }
    }

    .container {
      flex: auto;
      overflow: auto;
      // > * {
      //   height: 100%;
      // }
    }
  }
  .temp-box-item{
    margin: 5px 30px;
    background: #f5f6f7;
    position: relative;
    .delete-icon{
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      color: red;
    }
    &:hover{
      .delete-icon {
        display: block;
        cursor: pointer;
      }
    }
  }
}

.body-header {
  height: auto;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &-item {
    min-width: 200px;
    margin-right: 30px;
  }
}
.body-container {
  padding: 5px;
  width: 70%;
  height: auto;
  margin: 0 auto;
  overflow-y: auto;
  margin-bottom: 30px;

  /deep/ .el-form-item__label {
    float: none;
  }

  /deep/ .el-tabs__item.is-active,
  .el-tabs__active-bar {
    background: #ecf0f1;
  }

  /deep/ .el-tabs__item {
    padding: 0px 10px;
    text-align: center;
    width: 120px;
  }

  /deep/ .el-tabs__header{
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .input-box {
    display: flex;
    .input-number {
      width: 140px;
      margin: 0 10px;
    }
  }
  .connection-box {
    width: 220px;
    position: relative;
    .connection-style {
      position: absolute;
      right: -20px;
      width: 20px;
      top: 14px;
      bottom: 14px;
      border: 1px solid #ccc;
      border-left-color: transparent;
      .icon-connection {
        position: absolute;
        right: -30px;
        top: 60%;
        transform: translate(0, -50%);
      }
      .btn-auto-center {
        width: 100px;
        position: absolute;
        right: -110px;
        top: 20%;
        cursor: pointer;
      }
    }
  }
  .icon-box {
    position: absolute;
    right: 0;
    top: 0;
    height: 90px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    color: #ccc;

    .icon {
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #333;
      }
    }
  }
  .pop-container {
    position: relative;
    // height: 90px;
    z-index: 1;
  }
  .select-box {
    background-color: #f5f6f7;
    position: relative;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    // height: 90px;
    padding: 10px 10px 10px 0;
    .item {
      width: 60px;
      height: 60px;
      border: 1px solid #ccc;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: none;
      justify-content: center;
      color: #666;
      cursor: pointer;
      margin: 0 0 10px 8px;
      .type {
        font-size: 14px;
      }
      .size {
        font-size: 12px;
      }
      &:hover {
        color: #409eff;
        border-color: #409eff;
      }
    }
    .act-item {
      color: #409eff;
      border-color: #409eff;
    }
  }
  .act-select-box {
    height: unset;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    box-shadow: 4px 4px 10px #ccc;
    .icon {
      transform: rotate(90deg);
    }
  }

  .core-format-input {
    margin: 0 10px;
    /deep/ .el-input {
      width: 140px;
      input {
        height: 28px;
        line-height: 28px;
        padding: 0 15px;
      }
    }
    /deep/ .unit {
      font-size: 14px;
      padding-left: 10px;
      color: #333;
    }
  }

  .cmyk-config {
    background-color: #f0fafe;
    padding: 10px;
    margin-top: 10px;
  }

  .item-inline {
    display: flex;
    /deep/ &>.el-form-item__content {
      min-width: 500px;
    }
  }
  .fbd-form {
    /deep/ .el-form-item__content {
      margin-left: 0 !important;
    }
  }

  /deep/.cke_top {
    padding:0 !important;
  }

  .column_fbd_label {
    display: inline-block;
    margin-bottom: 10px;
  }
}
.chapter_class {
  color:'#293a5c';
  padding: 10px;
  padding-top:0;
  font-weight: 500;
  /deep/ .el-input {
    width: 210px;
  }
  /deep/ .el-input__inner {
    width: 200px;
  }
}

.chapter_tabs {
  /deep/ .el-tabs__header{
    height: 41px;
  }
}
.rename_chapter_class {

  display: inline-block;
  /deep/ .el-input {
    width: 210px;
  }
  /deep/ .el-input__inner {
    width: 200px;
  }
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.checkbox-width {
  width: 100px;
}

.base-style {
  display: flex;
  flex-wrap: wrap;
  .base-item {
    flex: none;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
  .base-label {
    height: 17px;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    text-align: right;
  }
  .unit {
    font-size: 12px;
    color: #ccc;
    margin-left: 4px;
  }
}
</style>
