export const defaultJournalCfg = {
  doi_number: { code_template: '' },
  title_cn: { code_template: '' },
  author_cn: { code_template: '' },
  address_cn: { code_template: '' },
  abstract_cn: { code_template: '' },
  abstract_cn_content: { code_template: '' }, // 中文摘要内容
  keywords_cn: { code_template: '' },
  keywords_cn_content: { code_template: '' }, // 中文关键词内容
  clc_number: { code_template: '' },
  essay_code: { code_template: '' },
  essay_number: { code_template: '' },
  footnote: { code_template: '' },
  footnote_content: { code_template: '' },
  title_en: { code_template: '' },
  author_en: { code_template: '' },
  address_en: { code_template: '' },
  abstract_en: { code_template: '' },
  abstract_en_content: { code_template: '' }, // 英文摘要内容
  keywords_en: { code_template: '' },
  keywords_en_content: { code_template: '' }, // 英文关键词内容
  essay_title_1: { code_template: '' },
  essay_title_2: { code_template: '' },
  essay_title_3: { code_template: '' },
  essay_title_4: { code_template: '' },
  essay_table: { code_template: '' },
  essay_chart: { code_template: '' },
  ref_title: { code_template: '' },
  ref_list: { code_template: '' },
};

export const defaultSemicCfg = {
  main_title: '',
  sub_title: '',
  exam_desc: '',
  quest_desc: '',
  essay_title: '',
  fix_title: '',
  knowledge_title: '',
};

export const defaultStyle = {
  book_page_cfg: {
    page_size: {
      name: 'A4',
      width: '210mm',
      height: '297mm',
    },
    page_core_size: {
      width: '180mm',
      height: '267mm',
    },
    page_margin: {
      top: '15mm',
      bottom: '15mm',
      left: '15mm',
      right: '15mm',
    },
    paper_orientation: 'portrait',
    global_column: {
      column_count: 2,
      column_gap: '10mm',
    },
    global_color: [],
  },
  book_default_style: {
    font_size: '10.5pt',
    font_family_cn: 'SongTi',
    font_family_en: 'Baizheng',
    font_family_num: 'Baizheng',
    font_color: { rgb: [0, 0, 0] },
    text_align: 'left',
    line_spacing: 0.5,
    global_color: [],
  },
  components: [
    {
      type: 'text',
      page_cfg: {
        page_size: {
          name: 'A4',
          width: '210mm',
          height: '297mm',
        },
        page_core_size: {
          width: '180mm',
          height: '267mm',
        },
        page_margin: {
          top: '15mm',
          bottom: '15mm',
          left: '15mm',
          right: '15mm',
        },
        paper_orientation: 'portrait',
        // global_column: {
        //   column_count: 2,
        //   column_gap: '10mm',
        // },
        global_column_fbd: {
          enable_fbd: true,
          min_column_width: '',
          code_template: '',

          is_use_word_fl: false,
        },
        pro_fbd: {
          code_template: '',
        },
      },
      default_style: {
        font_size: '10.5pt',
        font_family_cn: 'SongTi',
        font_family_en: 'Baizheng',
        font_family_num: 'Baizheng',
        font_color: { rgb: [0, 0, 0] },
        text_align: 'left',
        line_spacing: 0.5,
      },
      class_cfg_list: [
        // {
        //   identity: {
        //     content: {
        //       level: 1,
        //     },
        //     _parent: {},
        //     node_type: 'chapter',
        //   },
        //   style: {
        //     _self: {
        //       font_size: '',
        //       font_family_cn: '',
        //       font_family_en: '',
        //       font_family_num: '',
        //       text_bold: '',
        //       font_color: {
        //         cmyk: [],
        //       },
        //       line_spacing: '',
        //       text_align: '',
        //       font_background: {
        //         cmyk: [],
        //       },
        //       before: {
        //         type: '',
        //         content: '',
        //         font_size: '',
        //         font_family_cn: '',
        //         font_family_en: '',
        //         font_family_num: '',
        //         font_color: {
        //           cmyk: [],
        //         },
        //         font_background: {
        //           cmyk: [],
        //         },
        //         decorations: [],
        //       },
        //       after: {
        //         type: '',
        //         content: '',
        //         font_size: '',
        //         font_family_cn: '',
        //         font_family_en: '',
        //         font_family_num: '',
        //         font_color: {
        //           cmyk: [],
        //         },
        //         font_background: {
        //           cmyk: [],
        //         },
        //         decorations: [],
        //       },
        //       line_height: '',
        //       text_decoration: '',
        //     },
        //   },
        //   display: {
        //     _self: true,
        //     body: true,
        //     qrcode: false,
        //   },
        //   config: {
        //     _self: {
        //       column: {
        //         column_count: '',
        //         column_gap: '',
        //         line_type: '',
        //         column_weight: '',
        //         line_color: {
        //           rgb: '',
        //           cmyk: [],
        //         },
        //       },
        //       hanging_space: '',
        //       margin: {
        //         before: '',
        //         after: '',
        //         top: '',
        //         bottom: '',
        //         left: '',
        //       },
        //       indent: '',
        //       is_in_column: '',
        //       page_break: '',
        //       decorations: [],
        //       is_chapter_num_zk: true,
        //     },
        //     qrcode: {
        //       text: 'http://publish.hexin.im/api/pool/short/map?id={node_id}',
        //       display: '',
        //       size: {
        //         width: '15mm',
        //         height: '15mm',
        //       },
        //       align: '',
        //     },
        //   },
        //   html: {
        //     _self: {
        //       _keyword: [],
        //     },
        //   },
        //   html_style_clean: {},
        //   fbd: {
        //     enable_fbd: true,
        //     code_template: '',
        //     is_in_column: true,
        //     is_lm: false,
        //   },
        // },
        // {
        //   identity: {
        //     content: {
        //       level: 2,
        //     },
        //     _parent: {},
        //     node_type: 'chapter',
        //   },
        //   style: {
        //     _self: {
        //       font_size: '',
        //       font_family_cn: '',
        //       font_family_en: '',
        //       font_family_num: '',
        //       text_bold: '',
        //       font_color: {
        //         cmyk: [],
        //       },
        //       line_spacing: '',
        //       text_align: '',
        //       font_background: {
        //         cmyk: [],
        //       },
        //       before: {
        //         type: '',
        //         content: '',
        //         font_size: '',
        //         font_family_cn: '',
        //         font_family_en: '',
        //         font_family_num: '',
        //         font_color: {
        //           cmyk: [],
        //         },
        //         font_background: {
        //           cmyk: [],
        //         },
        //         decorations: [],
        //       },
        //       after: {
        //         type: '',
        //         content: '',
        //         font_size: '',
        //         font_family_cn: '',
        //         font_family_en: '',
        //         font_family_num: '',
        //         font_color: {
        //           cmyk: [],
        //         },
        //         font_background: {
        //           cmyk: [],
        //         },
        //         decorations: [],
        //       },
        //       line_height: '',
        //       text_decoration: '',
        //     },
        //   },
        //   display: {
        //     _self: true,
        //     body: true,
        //     qrcode: false,
        //   },
        //   config: {
        //     _self: {
        //       column: {
        //         column_count: '',
        //         column_gap: '',
        //         line_type: '',
        //         column_weight: '',
        //         line_color: {
        //           rgb: '',
        //           cmyk: [],
        //         },
        //       },
        //       hanging_space: '',
        //       margin: {
        //         before: '',
        //         after: '',
        //         top: '',
        //         bottom: '',
        //         left: '',
        //       },
        //       indent: '',
        //       is_in_column: '',
        //       page_break: '',
        //       decorations: [],
        //       is_chapter_num_zk: true,
        //     },
        //     qrcode: {
        //       text: 'http://publish.hexin.im/api/pool/short/map?id={node_id}',
        //       display: '',
        //       size: {
        //         width: '15mm',
        //         height: '15mm',
        //       },
        //       align: '',
        //     },
        //   },
        //   html: {
        //     _self: {
        //       _keyword: [],
        //     },
        //   },
        //   html_style_clean: {},
        //   fbd: {
        //     enable_fbd: true,
        //     code_template: '',
        //     is_in_column: true,
        //     is_lm: false,
        //   },
        // },
        // {
        //   identity: {
        //     content: {
        //       level: 3,
        //     },
        //     _parent: {},
        //     node_type: 'chapter',
        //   },
        //   style: {
        //     _self: {
        //       font_size: '',
        //       font_family_cn: '',
        //       font_family_en: '',
        //       font_family_num: '',
        //       text_bold: '',
        //       font_color: {
        //         cmyk: [],
        //       },
        //       line_spacing: '',
        //       text_align: '',
        //       font_background: {
        //         cmyk: [],
        //       },
        //       before: {
        //         type: '',
        //         content: '',
        //         font_size: '',
        //         font_family_cn: '',
        //         font_family_en: '',
        //         font_family_num: '',
        //         font_color: {
        //           cmyk: [],
        //         },
        //         font_background: {
        //           cmyk: [],
        //         },
        //         decorations: [],
        //       },
        //       after: {
        //         type: '',
        //         content: '',
        //         font_size: '',
        //         font_family_cn: '',
        //         font_family_en: '',
        //         font_family_num: '',
        //         font_color: {
        //           cmyk: [],
        //         },
        //         font_background: {
        //           cmyk: [],
        //         },
        //         decorations: [],
        //       },
        //       line_height: '',
        //       text_decoration: '',
        //     },
        //   },
        //   display: {
        //     _self: true,
        //     body: true,
        //     qrcode: false,
        //   },
        //   config: {
        //     _self: {
        //       column: {
        //         column_count: '',
        //         column_gap: '',
        //         line_type: '',
        //         column_weight: '',
        //         line_color: {
        //           rgb: '',
        //           cmyk: [],
        //         },
        //       },
        //       hanging_space: '',
        //       margin: {
        //         before: '',
        //         after: '',
        //         top: '',
        //         bottom: '',
        //         left: '',
        //       },
        //       indent: '',
        //       is_in_column: '',
        //       page_break: '',
        //       decorations: [],
        //       is_chapter_num_zk: true,
        //     },
        //     qrcode: {
        //       text: 'http://publish.hexin.im/api/pool/short/map?id={node_id}',
        //       display: '',
        //       size: {
        //         width: '15mm',
        //         height: '15mm',
        //       },
        //       align: '',
        //     },
        //   },
        //   html: {
        //     _self: {
        //       _keyword: [],
        //     },
        //   },
        //   html_style_clean: {},
        //   fbd: {
        //     enable_fbd: true,
        //     code_template: '',
        //     is_in_column: true,
        //     is_lm: false,
        //   },
        // },
        {
          identity: {
            node_type: 'question',
            content: {},
          },
          style: {
            _self: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              fbd: {},
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            body: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            serial_number: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '11111',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '2222222222',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            source: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '333333333333',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            answer: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            analysis: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            choices: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            choice_letter: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            choice_option: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
          },
          config: {
            body: {
              indent: '',
              blank_with_brackets: false,
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
            bracket: {
              autofill: '',
              float: '',
              length: '',
              length_cfg: {
                mode: 'full',
                length: 2,
              },
            },
            _self: {
              hanging_space: '',
              margin: {
                top: '',
                bottom: '',
                left: '',
              },
              indent: '',
              continuous_array: {
                effect: '',
                width: '',
              },
              children_dw: {
                per_line: '',
                spacing: [],
              },
              blank_both_sides_space: '',
            },
            serial_number: {
              layout: '',
              pattern: '',
              margin: {
                before: '',
                after: '',
              },
              decorations: [],
              fbd: {
                enable_fbd: true,
                code_template: '',
                is_zk_for_level_1: true,
                is_zk_for_level_2: false,
              },
              global_zk: false, // 使用全局ZK，默认 false
            },
            serial_number_cn: {
              fbd: {
                code_template: '',
              }
            },
            answer: {
              is_in_answer_space: '',
              indent: '',
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
            analysis: {
              indent: '',
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
            source: {
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
            choices: {
              option_spacing: [],
              choice_per_line: '',
              letter_layout: '',
              image_option_letter_position: '',
              margin: {
                left: '',
                top: '',
              },
            },
            answer_space: {
              height: '',
              layout: 'default',
            },
            qrcode: {
              text: 'http://publish.hexin.im/api/pool/short/map?id={node_id}',
              display: '',
              size: {
                width: '15mm',
                height: '15mm',
              },
              align: '',
            },
            fk: {
              group: '',
              margin: {
                top: '',
                bottom: '',
                left: '',
                right: '',
              },
              padding: {
                top: '',
                bottom: '',
                left: '',
                right: '',
              },
              background: {
                rgb: [],
                cmyk: [],
              },
              border_type: '',
              border_image: {
                header: '',
                footer: '',
                left: '',
                right: '',
              },
              border: {
                type: '',
              },
            },
            content_split: {
              refer_order: 'default',
              refer_alternate: false,
              answer: {
                before: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                line_break: false,
                delimiter: '',
              },
              analysis: {
                before: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                line_break: false,
                delimiter: '',
              },
              cross_cfg: {
                answer_analysis_arrangement_mode: 'break_line',
              },
            },
          },
          html: {
            _self: {
              _keyword: [],
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
              table: {
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                text_bold: '',
                font_color: {
                  cmyk: [],
                },
                line_spacing: '',
                text_align: '',
                font_background: {
                  cmyk: [],
                },
                before: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                after: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                font_style: {
                  top: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                  left: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
                fill: {
                  crma: {
                    rgb: '',
                    cmyk: [],
                  },
                  top: {
                    rgb: '',
                    cmyk: [],
                  },
                  left: {
                    rgb: '',
                    cmyk: [],
                  },
                },
                border: {
                  outer: {
                    type: '',
                    color: {
                      rgb: '',
                      cmyk: [],
                    },
                    weight: '',
                  },
                  inner: {
                    type: '',
                    color: {
                      rgb: '',
                      cmyk: [],
                    },
                    weight: '',
                  },
                },
              },
            },
            body: {
              _keyword: [],
              img: {
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
            answer: {
              _keyword: [],
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
            analysis: {
              _keyword: [],
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
            choices: {
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
          },
          display: {
            _self: null,
            body: null,
            serial_number: null,
            source: null,
            answer: null,
            analysis: null,
            choices: null,
            choice_letter: null,
            choice_option: null,
            answer_space: null,
            qrcode: null,
          },
          html_style_clean: {},
        },
        {
          identity: {
            node_type: 'question',
            content: {
              question_type: 'choice',
            },
          },
          style: {
            _self: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            body: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            serial_number: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            source: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            answer: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            analysis: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            choices: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            choice_letter: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            choice_option: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
          },
          config: {
            body: {
              indent: '',
              blank_with_brackets: false,
              // fbd: {
              //   enable_fbd: true,
              //   code_template: '',
              // },
            },
            bracket: {
              autofill: '',
              float: '',
              length: '',
              length_cfg: {
                mode: 'full',
                length: 2,
              },
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
            _self: {
              hanging_space: '',
              margin: {
                top: '',
                bottom: '',
                left: '',
              },
              indent: '',
              continuous_array: {
                effect: '',
                width: '',
              },
              children_dw: {
                per_line: '',
                spacing: [],
              },
              blank_both_sides_space: '',
            },
            serial_number: {
              layout: '',
              pattern: '',
              margin: {
                before: '',
                after: '',
              },
              decorations: [],
              // fbd: {
              //   enable_fbd: true,
              //   code_template: '',
              // },
            },
            answer: {
              is_in_answer_space: '',
              indent: '',
              // fbd: {
              //   enable_fbd: true,
              //   code_template: '',
              // },
            },
            analysis: {
              indent: '',
              // fbd: {
              //   enable_fbd: true,
              //   code_template: '',
              // },
            },
            choices: {
              option_spacing: [],
              choice_per_line: '',
              letter_layout: '',
              image_option_letter_position: '',
              margin: {
                left: '',
                top: '',
              },
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
              choices_dw_indent: false, // 选项使用DW缩进
            },
            answer_space: {
              height: '',
              layout: 'default',
            },
            qrcode: {
              text: 'http://publish.hexin.im/api/pool/short/map?id={node_id}',
              display: '',
              size: {
                width: '15mm',
                height: '15mm',
              },
              align: '',
            },
            fk: {
              group: '',
              margin: {
                top: '',
                bottom: '',
                left: '',
                right: '',
              },
              padding: {
                top: '',
                bottom: '',
                left: '',
                right: '',
              },
              background: {
                rgb: [],
                cmyk: [],
              },
              border_type: '',
              border_image: {
                header: '',
                footer: '',
                left: '',
                right: '',
              },
              border: {
                type: '',
              },
            },
            content_split: {
              refer_order: 'default',
              refer_alternate: false,
              answer: {
                before: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                line_break: false,
                delimiter: '',
              },
              analysis: {
                before: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                line_break: false,
                delimiter: '',
              },
              cross_cfg: {
                answer_analysis_arrangement_mode: 'break_line',
              },
            },
            choice_letter: {
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
          },
          html: {
            _self: {
              _keyword: [],
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
              table: {
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                text_bold: '',
                font_color: {
                  cmyk: [],
                },
                line_spacing: '',
                text_align: '',
                font_background: {
                  cmyk: [],
                },
                before: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                after: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                font_style: {
                  top: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                  left: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
                fill: {
                  crma: {
                    rgb: '',
                    cmyk: [],
                  },
                  top: {
                    rgb: '',
                    cmyk: [],
                  },
                  left: {
                    rgb: '',
                    cmyk: [],
                  },
                },
                border: {
                  outer: {
                    type: '',
                    color: {
                      rgb: '',
                      cmyk: [],
                    },
                    weight: '',
                  },
                  inner: {
                    type: '',
                    color: {
                      rgb: '',
                      cmyk: [],
                    },
                    weight: '',
                  },
                },
              },
            },
            body: {
              _keyword: [],
              img: {
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
            answer: {
              _keyword: [],
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
            analysis: {
              _keyword: [],
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
            choices: {
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
          },
          display: {
            _self: null,
            body: null,
            serial_number: null,
            source: null,
            answer: null,
            analysis: null,
            choices: null,
            choice_letter: null,
            choice_option: null,
            answer_space: null,
            qrcode: null,
          },
          html_style_clean: {},
        },
      ],
      node_cfg_list: [],
      margin_components: [
        {
          margin_type: 'header',
          page_selector: 'page',
          relative_margin: '0mm',
          margin_before: '0mm',
          offset: '0mm',
          data: [],
          fbd: {
            enable_fbd: true,
            code_template: '',
          },
        },
        {
          margin_type: 'footer',
          page_selector: 'page',
          relative_margin: '0mm',
          margin_before: '0mm',
          offset: '0mm',
          data: [],
          fbd: {
            enable_fbd: true,
            code_template: '',
          },
        },
        {
          margin_type: 'left',
          page_selector: 'page',
          relative_margin: '0mm',
          margin_before: '0mm',
          offset: '0mm',
          data: [],
          fbd: {
            enable_fbd: true,
            code_template: '',
          },
        },
        {
          margin_type: 'right',
          page_selector: 'page',
          relative_margin: '0mm',
          margin_before: '0mm',
          offset: '0mm',
          data: [],
          fbd: {
            enable_fbd: true,
            code_template: '',
          },
        },
      ],
      output_cfg: {
        type: 'founder',
        version: '10.0',
      },
      extra_cfg: {
        latex_autofix: false,
        // choise_auto_layout: false,
        img_auto_layout: false,
        img_auto_covert: false,

        is_use_word_fl: true, // 分栏
        extract_annotation: true, // 提取批注

        formula_compatibility_up: true, // 公式兼容性增强
        zx_asssist: true, // 正斜体修复 正斜体辅助
        formula_autofix: true, // 公式纠错
        formula_img_recognition: true, // 公式图像识别

        choise_auto_layout: true, // 选择题选项布局
        auto_zk: true, // 自动字空
        image_auto_layout: true, // 图片自动布局  图文布局优化

        image_to_gray: false, // 灰度图像转化
        complex_table: true, // 复杂表格,
        image_auto_recognition: true, // 图片自动识别 试题截图识别

        ignore_word_style_cfg: {
          bold: false, // 忽略 word 加粗
          color: false, // 忽略 word 字色
        },

        image_name_prefix: '', // 图片名称前缀

        indent_format: '', // 格式化缩进

        bold_repl_cx_hth: 'cx', // 加粗文本渲染为
        global_wtbx_li: true, // 理科是否使用全局外体白斜，默认 true
        thousandth_space: 'none', // 千分位配置  无|三分之一空|逗号，默认 none
        refresh_zx: false, // 是否全局刷正斜体，默认 false
        blank_fbd_code: 'zz', // 下划线方正代码  ZZ注解|CD注解  默认zz

        blank_fixed_length: {
          first: 0,
          second: 0,
        }
      },
      partial_cfg: {
        table_font: {
          font_size: '10.5pt',
          font_family_cn: '',
          font_family_en: '',
          font_family_num: '',
          // font_color: { rgb: [0, 0, 0] },
          text_align: 'left',
          line_spacing: 0.5,
          del_indent: false,
          simple_table_cfg: {
            top_space: '1mm', // 上空，默认写入 1mm
            bottom_space: '1mm', // 下空，默认写入 1mm
          },
          continued_table: { // 续表三方代码
            code_template: '',
          },
          table_title: {
            code_template: ''
          },
          single_table_cfg: {
            is_convert_fk: false, // 单行单列表是否转为方框，默认 false
            fk_start: {
              code_template: '' // 方框开始的三方代码
            },
            fk_end: {
              code_template: '' // 方框结束的三方代码
            },
          }
        },
        keyword_cfg: {},
        semic_cfg: defaultSemicCfg,
        extra_content: {
          code_template: '', // 三方代码
          location: 'start', // 全文开头 | 一级标题前
          is_in_column: false, // 是否在分栏内
        },
        wb_cfg: {
          '4': { 'fbd': '' },
          '3': { 'fbd': '' },
        },
        formula_cfg: { // 公式配置
          jb_use_gt_2: false, // 公式相关配置-JB 占两行时，是否使用 JB<2 写法
        },
      },
      journal_cfg: defaultJournalCfg,
    },
  ],
};
